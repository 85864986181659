.Modal-modal-0-1-3 {
  top: 10%; }

.modal {
  padding: 0 100px 50px;
  margin-top: 5%; }
  .modal > h2 {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 16px; }
  .modal > button {
    margin-right: 50px; }
    .modal > button a {
      text-decoration: none; }
  .modal > p {
    margin: 0 auto;
    font-family: sba, sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    color: black;
    max-width: 75%; }
    .modal > p.sub-title {
      font-size: 1.28em;
      letter-spacing: 0.6px;
      line-height: 1.56;
      color: #404040;
      margin-bottom: 1.8em; }
  .modal > div {
    display: flex;
    align-items: center;
    padding-top: 45px;
    text-align: center;
    justify-content: center; }
    .modal > div > section {
      display: flex;
      flex-flow: column;
      font-size: 2em;
      padding: 0 12px; }
      .modal > div > section > span {
        font-size: 32px !important; }
      .modal > div > section.new {
        color: black; }
    .modal > div > button:first-child:not(:only-child) {
      margin-right: 25px !important; }
  .modal.join-reserve-modal {
    padding: 0 10px 50px; }

@media only screen and (max-width: 480px) {
  .modal {
    padding: 0 5% 50px; }
  .modal > p {
    max-width: 100%; } }
