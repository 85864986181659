.sc-list {
  padding-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;
  margin: 10px 0; }
  .sc-list.card {
    border-bottom: 0; }
  .sc-list header {
    color: #333333;
    font-size: 11px;
    margin: 15px 12px;
    font-weight: 500; }
  .sc-list.heavy-padding {
    margin-top: 25px; }
    .sc-list.heavy-padding > .sc-list-item {
      padding: 25px 12px; }
  .sc-list.border-top {
    border-top: 1px solid #d9d9d9; }

.sc-list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px;
  font-size: 16px;
  font-weight: 300; }
  .sc-list-item > div:first-child {
    color: #333333; }
  .sc-list-item > div:last-child {
    color: black;
    font-weight: 500; }
