.sc-address {
  text-decoration: none; }
  .sc-address:hover {
    text-decoration: underline; }
  .sc-address > address {
    display: flex;
    font-style: normal;
    font-size: 14px;
    line-height: 1.5em;
    color: black; }
    .sc-address > address > div > span > i.material-icons {
      font-size: 16px;
      padding-right: 4px;
      color: black; }
