.booking-container {
  display: flex;
  flex-flow: column;
  flex: 1;
  padding-bottom: 150px; }
  .booking-container > header {
    flex: 0 0 auto;
    text-align: center; }
  .booking-container > section {
    display: flex;
    justify-content: center;
    margin: 0 auto; }
    .booking-container > section .section-container {
      align-items: center;
      flex: 0 1 auto; }
    .booking-container > section .location-section {
      margin: 1.5rem 0 0; }
    @media only screen and (min-width: 960px) {
      .booking-container > section .location-section {
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        flex-basis: auto; } }
    @media only screen and (min-width: 1280px) {
      .booking-container > section .location-section {
        grid-template-columns: repeat(4, minmax(0, 1fr)); } }
    .booking-container > section .location {
      box-sizing: border-box; }
      .booking-container > section .location :hover {
        border-radius: 4px; }
      .booking-container > section .location .address {
        color: #808080; }
      .booking-container > section .location .partner-location {
        padding-top: 0.19rem; }
      .booking-container > section .location .partner-location-info {
        color: #808080;
        font-size: 1.1rem;
        margin-right: 0.3rem;
        vertical-align: middle; }
    .booking-container > section .gray-bar {
      display: flex;
      flex: 0 0 auto;
      background-color: #f4f4f4;
      max-width: 100vw !important;
      justify-content: center; }
  .booking-container .selection-inputs {
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem; }
  .booking-container > footer {
    z-index: 10;
    flex: 0 0 auto; }

@media only screen and (max-width: 840px) {
  .booking-container > section .section-container {
    align-items: normal; } }

@media only screen and (max-width: 480px) {
  .booking-container {
    max-width: 100vw; }
    .booking-container > footer {
      flex-direction: row-reverse; } }
