@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Open+Sans:300,400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons&display=swap);
.main-container {
  background-color: #f1f4f6;
  width: 100%;
  height: 100vh;
  overflow: hidden; }

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  width: 80%;
  margin-inline: auto; }

.message-container {
  display: flex;
  align-items: center;
  flex-direction: column; }

.icon-container {
  margin-bottom: 0.75rem;
  padding: 1rem; }

.icon {
  vertical-align: middle;
  height: 1.5rem;
  width: auto; }

.message {
  padding-inline: 0.5rem; }

.text-center {
  text-align: center; }

.app-message-container {
  padding-top: 15%; }

.links-container {
  display: flex;
  flex-direction: row;
  justify-content: center; }

@media only screen and (max-width: 480px) {
  .links-container {
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .audi-text-logo {
    display: none; } }

html {
  display: flex;
  width: 100vw;
  height: 100%;
  max-width: 100vw; }
  html div#root {
    height: 100%; }
  html > body {
    display: flex;
    flex-flow: column;
    flex: auto;
    padding: 0;
    margin: 0;
    color: black; }

.http-form-status {
  display: flex;
  flex-flow: column;
  flex: 0 0 auto !important;
  justify-content: center;
  align-items: center;
  padding: 16px; }
  .http-form-status > h2 {
    flex: 1 0 100%; }
  .http-form-status.error {
    color: white;
    background: #d35656; }
  .http-form-status.success {
    color: white;
    background: black; }

.claim-code-input {
  flex-grow: 0.9; }

.submit-always-audi-button {
  height: 2.8rem; }

.claim-code-input-flexbox {
  display: flex;
  flex-flow: row wrap;
  max-width: 34rem; }

.inner-row {
  display: flex;
  flex-direction: row; }

.inner-row-column {
  display: flex;
  flex-direction: row; }

.left-column {
  width: 100%; }

.right-column {
  width: 100%;
  margin-left: 40px !important; }

.book-now-button {
  width: auto; }

@media (max-width: 960px) {
  .claim-code-input-flexbox {
    flex-direction: column;
    width: 85vw;
    max-width: 85vw; }
  hr.always-audi-program-rule {
    margin-left: 0 !important;
    margin-bottom: 2rem !important; }
  .audi-days-remaining {
    margin-left: 0 !important; }
  .inner-row-column {
    display: flex;
    flex-direction: column; }
  .right-column {
    margin-left: 0 !important; }
  .book-now-button {
    margin-left: 0 !important; }
  .always-audi-program {
    width: 100vw;
    max-width: 100vw; }
  .account-tab-text {
    word-wrap: break-word;
    width: 85vw !important; } }

iframe form label {
  left: 0 !important; }

.hosted-field-container {
  margin: 0.8em 0 1.3em; }
  .hosted-field-container .bt-label {
    line-height: 20px;
    color: #333333;
    font-size: 12px;
    font-family: AudiType-Variable, sans-serif;
    font-stretch: 105%; }

.hosted-field {
  height: 32px;
  display: block;
  background-color: transparent;
  color: black;
  border: none;
  border-bottom: 1px solid black;
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative; }

/* --------------------
BT HOSTED FIELDS SPECIFIC
-------------------- */
.braintree-hosted-fields-focused {
  border-bottom: 2px solid black;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }

.braintree-hosted-fields-invalid {
  border-bottom: 2px solid #d35656;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }

#credit-card-form {
  margin: 1.5rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  #credit-card-form #details-section,
  #credit-card-form #address-section {
    flex-direction: column; }
  #credit-card-form #details-section {
    width: 45%; }
  #credit-card-form #address-section {
    width: 45%; }
  #credit-card-form .half-section {
    display: flex;
    margin-top: 1rem;
    flex-direction: row;
    justify-content: space-between; }
  #credit-card-form .half {
    width: 45%; }
    #credit-card-form .half#province {
      width: 52.5%; }

#credit-card-button-section {
  display: flex;
  margin: 4rem 0 0;
  width: 100%;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  justify-content: flex-end; }

@media only screen and (max-width: 960px) {
  #credit-card-form {
    flex-direction: column; }
    #credit-card-form #details-section,
    #credit-card-form #address-section {
      flex-direction: column;
      width: 100%; }
    #credit-card-form #address-section {
      margin: 4.5rem 0 0; }
    #credit-card-form .half-section {
      flex-direction: column; }
    #credit-card-form .half {
      width: auto; }
      #credit-card-form .half#province {
        width: auto; }
  #credit-card-button-section {
    justify-content: center;
    flex-direction: column; }
  .credit-card-button {
    width: 100% !important; } }

.flex-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 3.938rem; }

.first-column {
  width: 100%;
  margin-right: 1.25rem; }

.second-column {
  width: 100%; }

.btn-group {
  float: right; }

.cancel-button {
  margin-right: 6px !important; }

#drivers-license-button-section {
  display: flex;
  margin: 4rem 0 0;
  width: 100%;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  justify-content: flex-end; }

@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
    align-items: flex-start; }
  .btn-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 29rem !important;
    float: none; }
  #drivers-license-button-section {
    justify-content: center;
    flex-direction: column; }
  .drivers-license-button {
    width: 100% !important; } }

.soft-optional label > span {
  display: none; }

.account-page-tab-content hr {
  margin-left: 54px;
  margin-right: 49px; }

.account-page-tab-content .button-container {
  display: flex;
  justify-content: flex-end; }

.account-page-tab-content .cancel-button {
  margin-right: 6px; }

@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
    align-items: flex-start; }
  .btn-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 29rem !important;
    float: none; }
  .button-container {
    justify-content: center;
    flex-direction: column; }
  .profile-button {
    width: 100% !important; }
  .cancel-button {
    margin-bottom: 6px !important; } }

.sc-account {
  flex: 1 1;
  display: flex;
  flex-flow: column; }
  .sc-account .account-page-tab-content {
    background-color: #f2f2f2;
    display: flex;
    padding: 4rem 3.18rem 3.81rem 3.75rem;
    width: inherit;
    flex-direction: column; }
  .sc-account .account-navigation-tab {
    max-width: 95vw; }
    .sc-account .account-navigation-tab div:first-child {
      width: 100%; }
  .sc-account .view-policies-link-button {
    margin: 4px 4px 4px auto; }
  .sc-account > div.row {
    flex: 1 1;
    display: flex;
    overflow-y: auto;
    justify-content: center; }
    .sc-account > div.row > div.inner {
      display: flex;
      flex: 0 1 1328px;
      flex-flow: column; }
    .sc-account > div.row > div > .sc-account-navigation {
      flex: 0 0 225px; }
    .sc-account > div.row > div > section {
      padding: 24px 0; }
      .sc-account > div.row > div > section > .account-container {
        background: white;
        padding: 24px;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

@media only screen and (max-width: 960px) {
  .sc-account .account-page-tab-content {
    margin-bottom: 2rem;
    padding: 1.5rem 1.25rem 2.5rem 1.31rem; }
  .sc-account > div.row {
    overflow-y: auto;
    padding: 0 1.125rem; }
    .sc-account > div.row > div.inner {
      flex: 1 1 auto;
      flex-flow: column;
      justify-content: space-evenly; }
      .sc-account > div.row > div.inner > .sc-account-navigation {
        flex: 0 0 auto;
        z-index: 10; }
      .sc-account > div.row > div.inner > section {
        overflow-y: inherit;
        padding: 0; }
        .sc-account > div.row > div.inner > section > .account-container {
          box-shadow: none; } }

.DayPickerNavigation_leftButton__horizontal {
  left: -1.5rem;
  border: none;
  height: 20.25rem; }
  .DayPickerNavigation_leftButton__horizontal:focus {
    outline: none; }

.DayPickerNavigation_rightButton__horizontal {
  right: -1.5rem;
  border: none;
  height: 20.25rem; }
  .DayPickerNavigation_rightButton__horizontal:focus {
    outline: none; }
  .DayPickerNavigation_rightButton__horizontal > svg {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

.date-picker {
  height: 100%;
  min-width: 450px; }
  .date-picker > .DayPicker {
    width: 100%;
    background: none;
    box-shadow: none; }
    .date-picker > .DayPicker .DayPicker_weekHeader {
      padding: 0; }
      .date-picker > .DayPicker .DayPicker_weekHeader > .DayPicker_weekHeader_ul {
        font-size: 1rem;
        font-family: AudiType, sans-serif;
        color: #808080; }
    .date-picker > .DayPicker .DayPicker_weekHeader_li > small {
      font-size: 0.75rem !important; }
    .date-picker > .DayPicker .DayPicker_weekHeaders__horizontal {
      margin-left: 25px; }
    .date-picker > .DayPicker .CalendarMonth_table {
      margin-top: 1rem; }
    .date-picker > .DayPicker .CalendarDay.CalendarDay__default {
      border: none;
      font-size: 0.75rem !important;
      font-family: AudiType, sans-serif;
      width: 51px !important;
      height: 42px !important;
      color: #333333; }
      .date-picker > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__highlighted_calendar {
        background: inherit;
        color: #333333; }
      .date-picker > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__blocked_out_of_range {
        color: #cacccd !important;
        background: white !important; }
      .date-picker > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected {
        background: black !important;
        color: #e5e5e5 !important; }
        .date-picker > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected.CalendarDay__blocked_out_of_range {
          color: white !important;
          background: black !important; }
      .date-picker > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected_span {
        color: #333333;
        background: #f2f2f2; }
        .date-picker > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected_span.CalendarDay__blocked_out_of_range {
          background: #f2f2f2 !important; }
          .date-picker > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected_span.CalendarDay__blocked_out_of_range.CalendarDay__selected_start {
            background: black !important; }
      .date-picker > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__hovered_span {
        color: black;
        background: #f2f2f2; }

.CalendarMonth .CalendarMonth_caption {
  font-size: 0.75rem;
  font-family: AudiType, sans-serif; }
  .CalendarMonth .CalendarMonth_caption > strong {
    color: #808080;
    font-weight: 100 !important; }

@media only screen and (max-width: 320px) {
  .CalendarMonth {
    padding-left: 6px; } }

@media only screen and (max-width: 960px) {
  .CalendarMonth .CalendarMonth_caption {
    font-size: 0.75rem;
    margin-top: 5px; }
    .CalendarMonth .CalendarMonth_caption > strong {
      color: #707070;
      font-weight: 100 !important; }
  .date-picker {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    padding: 0;
    min-height: 30rem; }
    .date-picker .DayPicker_transitionContainer {
      height: 31rem; }
    .date-picker .DayPicker_weekHeader_li {
      padding: 5px;
      margin-left: -1px; }
    .date-picker .DayPicker_weekHeader_li_1 > small > {
      margin-left: -75px;
      font-size: 0.75rem !important; }
    .date-picker .CalendarMonth .CalendarMonth_caption {
      font-size: 0.75rem !important;
      font-family: AudiType, sans-serif; }
      .date-picker .CalendarMonth .CalendarMonth_caption > strong {
        color: #808080;
        font-weight: 100 !important; }
    .date-picker .DayPicker .CalendarDay.CalendarDay__default {
      font-size: 0.75rem !important; }
    .date-picker .DayPicker_weekHeader__vertical {
      border: none;
      top: none !important;
      margin-left: -152px !important; }
    .date-picker .DayPickerNavigation_container__vertical {
      box-shadow: none; }
      .date-picker .DayPickerNavigation_container__vertical > button:first-child {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
      .date-picker .DayPickerNavigation_container__vertical > button:nth-of-type(2) {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg); }
        .date-picker .DayPickerNavigation_container__vertical > button:nth-of-type(2) > svg {
          margin-bottom: 24px; }
    .date-picker .CalendarMonth_table {
      margin: 0;
      width: 100%;
      height: 252px; }
    .date-picker .CalendarMonthGrid__vertical {
      scrollbar-width: none;
      overflow-y: hidden;
      width: 346px !important; }
    .date-picker .DayPickerNavigation_button {
      border: none; } }

.Modal-modal-0-1-3 {
  top: 10%; }

.modal {
  padding: 0 100px 50px;
  margin-top: 5%; }
  .modal > h2 {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 16px; }
  .modal > button {
    margin-right: 50px; }
    .modal > button a {
      text-decoration: none; }
  .modal > p {
    margin: 0 auto;
    font-family: sba, sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    color: black;
    max-width: 75%; }
    .modal > p.sub-title {
      font-size: 1.28em;
      letter-spacing: 0.6px;
      line-height: 1.56;
      color: #404040;
      margin-bottom: 1.8em; }
  .modal > div {
    display: flex;
    align-items: center;
    padding-top: 45px;
    text-align: center;
    justify-content: center; }
    .modal > div > section {
      display: flex;
      flex-flow: column;
      font-size: 2em;
      padding: 0 12px; }
      .modal > div > section > span {
        font-size: 32px !important; }
      .modal > div > section.new {
        color: black; }
    .modal > div > button:first-child:not(:only-child) {
      margin-right: 25px !important; }
  .modal.join-reserve-modal {
    padding: 0 10px 50px; }

@media only screen and (max-width: 480px) {
  .modal {
    padding: 0 5% 50px; }
  .modal > p {
    max-width: 100%; } }

.partner-location-modal {
  text-align: left;
  margin-top: 20px; }
  .partner-location-modal > p {
    margin: 0 auto;
    font-family: sba, sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    color: #a2a2a2;
    max-width: 90%; }
  .partner-location-modal-options {
    border-bottom: 1px solid #d9d9d9; }
    .partner-location-modal-options > h3 {
      padding-top: 1.25rem; }
    .partner-location-modal-options > p:last-child {
      padding-bottom: 49px; }
  .partner-location-modal > ul {
    padding: 0; }
    .partner-location-modal > ul > p {
      padding-top: 2px; }
      .partner-location-modal > ul > p > ul > li {
        -webkit-margin-after: 0% !important;
                margin-block-end: 0% !important;
        -webkit-margin-before: 0% !important;
                margin-block-start: 0% !important; }
        .partner-location-modal > ul > p > ul > li:first-child {
          padding-top: 2px; }
  .partner-location-modal .link {
    text-decoration: underline; }
    .partner-location-modal .link > p {
      margin-top: 30px; }

.MuiDialog-container {
  background-color: rgba(0, 0, 0, 0.5); }

.dialogHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start; }

.dialogTitle {
  justify-content: flex-start;
  margin-top: -16px; }

.dialogClose {
  justify-content: flex-end; }

.continue-btn {
  height: 58px;
  width: 144px; }

.booking-location {
  width: 33%; }

.booking-pickup-date {
  width: 25%; }

.booking-dropoff-date {
  width: 25%; }

@media only screen and (max-width: 960px) {
  .booking-location {
    width: 100% !important; }
  .booking-pickup-date {
    width: 100%; }
  .booking-dropoff-date {
    width: 100%; } }

.time-slider {
  flex: 1 1 auto;
  padding-left: 1.5rem;
  width: 20rem;
  border-left: 1px solid #b3b3b3; }

.pickup-time-slider {
  padding-top: 22px !important; }

.disabled {
  border-left: none; }

.locationDateTimeContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-content: space-between;
  max-width: 1328px;
  width: 100vw; }

.location-title-container {
  margin: 0.7rem 0;
  width: 100%; }

@media only screen and (max-width: 960px) {
  .subscription-location-date-time-submit-button {
    margin-top: 36px !important; }
  .pickup-time-slider {
    padding-top: unset !important; }
  .time-slider {
    border-left: unset;
    margin-left: 5.392px;
    padding-left: unset; } }

@media only screen and (max-width: 840px) {
  .time-slider {
    overflow: hidden;
    padding: 0;
    width: 100%; }
    .time-slider > div {
      height: unset;
      width: 100%; } }

@media only screen and (max-width: 480px) {
  .continue-btn {
    width: 100% !important; } }

.sc-vehicle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 1.25rem 0;
  margin-bottom: 1.25rem;
  border: 1px solid #e5e5e5; }
  .sc-vehicle .model-and-description.mobile {
    display: none; }
  .sc-vehicle > header {
    display: flex;
    flex-direction: row; }
    .sc-vehicle > header > img {
      width: 100%; }
  .sc-vehicle > section {
    margin-top: 1.4375rem;
    margin-right: 3.125rem; }
    .sc-vehicle > section .total-and-earn-points {
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }
    .sc-vehicle > section.pricing-and-availability-info {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
      width: 100%; }
      .sc-vehicle > section.pricing-and-availability-info .pricing-container-bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%; }
      .sc-vehicle > section.pricing-and-availability-info .pricing-container-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%; }
      .sc-vehicle > section.pricing-and-availability-info .cost-and-points {
        text-align: right; }
        .sc-vehicle > section.pricing-and-availability-info .cost-and-points h4.vehicle-unavailable-notice {
          color: #f50537; }
      .sc-vehicle > section.pricing-and-availability-info .cost-and-points-available {
        text-align: right; }
        .sc-vehicle > section.pricing-and-availability-info .cost-and-points-available h4.vehicle-unavailable-notice {
          color: #f50537; }
      .sc-vehicle > section.pricing-and-availability-info .loyalty-info-and-button {
        align-items: flex-end;
        display: flex;
        flex-direction: column; }
      .sc-vehicle > section.pricing-and-availability-info .model-and-description {
        display: flex;
        text-align: left; }
      .sc-vehicle > section.pricing-and-availability-info div > .earn-total > p {
        color: #666666; }
    .sc-vehicle > section > .earn-total > p {
      color: #666666; }
    .sc-vehicle > section > .earn-total,
    .sc-vehicle > section > .days-total {
      display: flex;
      flex-direction: row-reverse; }
    .sc-vehicle > section > .pricing {
      line-height: 1; }
      .sc-vehicle > section > .pricing.earn, .sc-vehicle > section > .pricing.owner-program {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
      .sc-vehicle > section > .pricing.spend {
        display: block; }
        .sc-vehicle > section > .pricing.spend > h2 {
          line-height: 1.5rem; }
      .sc-vehicle > section > .pricing > h4 {
        letter-spacing: -1px;
        margin: 0; }
        .sc-vehicle > section > .pricing > h4 > span {
          margin: 0;
          position: relative;
          right: 0.313rem;
          letter-spacing: normal; }
      .sc-vehicle > section > .pricing.owner-program > h4 {
        line-height: 1; }
        .sc-vehicle > section > .pricing.owner-program > h4 > .duration {
          margin-left: 0;
          position: static; }
        .sc-vehicle > section > .pricing.owner-program > h4 > .days {
          margin-left: 12px; }
      .sc-vehicle > section > .pricing.owner-program > .plus {
        height: auto;
        width: 50px;
        position: relative; }
        .sc-vehicle > section > .pricing.owner-program > .plus::before, .sc-vehicle > section > .pricing.owner-program > .plus::after {
          position: absolute;
          content: "";
          background-color: #979797; }
        .sc-vehicle > section > .pricing.owner-program > .plus::before {
          height: 27px;
          width: 1px;
          -webkit-transform: rotateX(180deg);
                  transform: rotateX(180deg);
          left: 22px;
          top: 4px; }
        .sc-vehicle > section > .pricing.owner-program > .plus::after {
          width: 27px;
          height: 1px;
          top: 17px;
          left: 9px; }
      .sc-vehicle > section > .pricing > .spend-points {
        display: flex;
        justify-content: flex-end; }
        .sc-vehicle > section > .pricing > .spend-points > span {
          color: #666666; }
        .sc-vehicle > section > .pricing > .spend-points > h4 {
          letter-spacing: -1px;
          margin: 0; }
          .sc-vehicle > section > .pricing > .spend-points > h4 > span {
            margin: 0;
            position: relative;
            right: 5px;
            letter-spacing: normal; }
    .sc-vehicle > section > button.currently-selected-button,
    .sc-vehicle > section > div > button.currently-selected-button {
      opacity: unset; }

.pricing-info-audi-days {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end; }

.days-bottom-margin {
  margin-bottom: 0.5rem !important; }

.plus {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

@media only screen and (min-width: 960px) {
  .vehicle-unavailable-notice {
    width: 11rem; } }

@media only screen and (max-width: 960px) {
  .sc-vehicle {
    padding: 0 20px 24px 10px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
    border-top: 1px solid #e5e5e5;
    border-left: none;
    border-right: none;
    border-bottom: none; }
    .sc-vehicle .model-and-description.mobile {
      display: block !important;
      padding-top: 18px; }
    .sc-vehicle > img {
      max-width: 100%; }
    .sc-vehicle > header {
      display: flex;
      flex-direction: column; }
    .sc-vehicle > section {
      margin: 0;
      width: 100%;
      padding-top: 0; }
      .sc-vehicle > section.pricing-and-availability-info {
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between; }
        .sc-vehicle > section.pricing-and-availability-info .pricing-container-bottom {
          align-items: flex-start; }
        .sc-vehicle > section.pricing-and-availability-info .pricing-container-top {
          width: auto; }
        .sc-vehicle > section.pricing-and-availability-info .cost-and-points {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%; }
        .sc-vehicle > section.pricing-and-availability-info .cost-and-points-available {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%; }
          .sc-vehicle > section.pricing-and-availability-info .cost-and-points-available > button {
            flex-direction: row; }
          .sc-vehicle > section.pricing-and-availability-info .cost-and-points-available > section {
            flex-direction: column; }
        .sc-vehicle > section.pricing-and-availability-info .model-and-description {
          display: none; }
      .sc-vehicle > section .insufficient-points-button,
      .sc-vehicle > section .select-vehicle-button,
      .sc-vehicle > section .vehicle-unavailable-button {
        width: auto; }
      .sc-vehicle > section .change-vehicle-button {
        max-width: 140px; }
      .sc-vehicle > section > button,
      .sc-vehicle > section > div > button {
        min-width: 8.5rem; }
      .sc-vehicle > section > .pricing.earn > div {
        display: flex;
        flex-direction: row; }
      .sc-vehicle > section > .pricing.spend {
        padding-top: 0; }
      .sc-vehicle > section > .pricing > h4 > span {
        right: 2px; }
      .sc-vehicle > section > .pricing.owner-program {
        line-height: normal; }
        .sc-vehicle > section > .pricing.owner-program > h4 > .days {
          margin-left: 0.5rem; }
        .sc-vehicle > section > .pricing.owner-program > .plus {
          height: auto;
          width: 40px;
          position: relative; }
          .sc-vehicle > section > .pricing.owner-program > .plus::before, .sc-vehicle > section > .pricing.owner-program > .plus::after {
            position: absolute;
            content: "";
            background-color: #979797; }
          .sc-vehicle > section > .pricing.owner-program > .plus::before {
            height: 21px;
            width: 1px;
            -webkit-transform: rotateX(180deg);
                    transform: rotateX(180deg);
            top: 0;
            left: 20px; }
          .sc-vehicle > section > .pricing.owner-program > .plus::after {
            width: 20px;
            height: 1px;
            top: 10px;
            left: 10px; }
      .sc-vehicle > section > .pricing > .spend-points > h4 {
        text-align: center; }
        .sc-vehicle > section > .pricing > .spend-points > h4 > span {
          right: 2px; }
      .sc-vehicle > section > .pricing > .spend-points > div {
        text-align: center; }
      .sc-vehicle > section > .earn-total {
        color: #666666;
        text-align: center; }
      .sc-vehicle > section > .days-total {
        text-align: center; }
  .vehicle-unavailable-notice {
    display: flex;
    flex-direction: row;
    margin-bottom: 0 !important;
    width: 11rem; }
  .program-name {
    padding-right: 20px; }
  .section-header {
    padding-left: 10px; } }

@media only screen and (max-width: 600px) {
  .sc-vehicle > section.pricing-and-availability-info .pricing-container-top {
    width: 100%; }
  .sc-vehicle > section .total-and-earn-points {
    flex-direction: row; }
  .sc-vehicle .vehicle-image {
    max-width: 100%; } }

@media only screen and (max-width: 480px) {
  .sc-vehicle {
    padding: 0 0 24px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
    border-top: 1px solid #e5e5e5;
    border-left: none;
    border-right: none;
    border-bottom: none; }
    .sc-vehicle .model-and-description.mobile {
      display: block !important;
      padding-top: 18px; }
    .sc-vehicle > img {
      max-width: 100%; }
    .sc-vehicle > header {
      display: flex;
      flex-direction: column; }
    .sc-vehicle > section {
      margin: 0;
      width: 100%;
      padding-top: 0; }
      .sc-vehicle > section .total-and-earn-points {
        display: flex;
        flex-direction: row;
        justify-content: flex-end; }
      .sc-vehicle > section.pricing-and-availability-info {
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between; }
        .sc-vehicle > section.pricing-and-availability-info .pricing-container-bottom {
          align-items: flex-start; }
        .sc-vehicle > section.pricing-and-availability-info .pricing-container-top {
          width: 100%; }
        .sc-vehicle > section.pricing-and-availability-info .cost-and-points {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%; }
        .sc-vehicle > section.pricing-and-availability-info .cost-and-points-available {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%; }
          .sc-vehicle > section.pricing-and-availability-info .cost-and-points-available > button {
            flex-direction: row; }
          .sc-vehicle > section.pricing-and-availability-info .cost-and-points-available > section {
            flex-direction: column; }
        .sc-vehicle > section.pricing-and-availability-info .model-and-description {
          display: none; }
      .sc-vehicle > section .insufficient-points-button,
      .sc-vehicle > section .select-vehicle-button,
      .sc-vehicle > section .vehicle-unavailable-button {
        width: auto; }
      .sc-vehicle > section .change-vehicle-button {
        max-width: 140px; }
      .sc-vehicle > section > button,
      .sc-vehicle > section > div > button {
        min-width: 8.5rem; }
      .sc-vehicle > section > .pricing.earn > div {
        display: flex;
        flex-direction: row; }
      .sc-vehicle > section > .pricing.spend {
        padding-top: 0; }
      .sc-vehicle > section > .pricing > h4 > span {
        right: 2px; }
      .sc-vehicle > section > .pricing.owner-program {
        line-height: normal; }
        .sc-vehicle > section > .pricing.owner-program > h4 > .days {
          margin-left: 0.5rem; }
        .sc-vehicle > section > .pricing.owner-program > .plus {
          height: auto;
          width: 40px;
          position: relative; }
          .sc-vehicle > section > .pricing.owner-program > .plus::before, .sc-vehicle > section > .pricing.owner-program > .plus::after {
            position: absolute;
            content: "";
            background-color: #979797; }
          .sc-vehicle > section > .pricing.owner-program > .plus::before {
            height: 21px;
            width: 1px;
            -webkit-transform: rotateX(180deg);
                    transform: rotateX(180deg);
            top: 0;
            left: 20px; }
          .sc-vehicle > section > .pricing.owner-program > .plus::after {
            width: 20px;
            height: 1px;
            top: 10px;
            left: 10px; }
      .sc-vehicle > section > .pricing > .spend-points > h4 {
        text-align: center; }
        .sc-vehicle > section > .pricing > .spend-points > h4 > span {
          right: 2px; }
      .sc-vehicle > section > .pricing > .spend-points > div {
        text-align: center; }
      .sc-vehicle > section > .earn-total {
        color: #666666;
        text-align: center; }
      .sc-vehicle > section > .days-total {
        text-align: center; }
    .sc-vehicle .righthand-of-card {
      width: auto; } }

.days-total {
  display: flex;
  flex-direction: row-reverse;
  text-align: right; }

.spend-points {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .spend-points > span {
    color: #666666; }

@media only screen and (max-width: 480px) {
  .spend-points {
    text-align: right; } }

.spend-total {
  color: #666666 !important; }
  .spend-total > span {
    color: inherit; }

@media only screen and (max-width: 480px) {
  .spend-total {
    text-align: right; } }

p.insufficient-points-text {
  color: #f50537; }

.loyalty-progress-text {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem; }
  .loyalty-progress-text > p {
    color: #666666; }
    .loyalty-progress-text > p.insufficient-points-text {
      color: #f50537; }

@media only screen and (max-width: 480px) {
  .loyalty-progress-text {
    justify-content: right; } }

.loyalty-points-messaging {
  margin-left: auto; }

.pricing-totals {
  color: #666666 !important;
  display: flex;
  justify-content: flex-end; }

.outer-coverage-container {
  border: 0.5px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4vh 2vw; }

.mobile-box {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: #d9d9d9;
  padding-top: 4vw;
  border-width: 0.25px;
  margin-left: 10vw;
  margin-right: 10vw;
  padding-right: 1rem;
  padding-bottom: 1rem; }

.mobile-text-area {
  margin: 1.125rem 10vw 0; }

.mobile-total-text {
  align-items: flex-end !important;
  justify-content: flex-end;
  margin-right: 10vw;
  margin-bottom: 3rem; }
  .mobile-total-text > .per-day-cost {
    align-items: baseline;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: row; }
  .mobile-total-text > h1,
  .mobile-total-text span {
    color: #333333 !important; }
  .mobile-total-text > p {
    color: #666666 !important; }

.inner-column {
  display: flex;
  flex-direction: column; }

.left-column {
  width: 50%; }

.right-column {
  width: 50%; }

.inner-row {
  display: flex;
  flex-direction: row; }

.auto-only-desktop {
  justify-content: space-between; }

.checkbox-container {
  justify-content: space-evenly;
  margin: 0; }

.checkbox {
  align-items: flex-start; }

.per-day {
  color: #666666 !important; }

.sub-header {
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  width: 100%; }
  .sub-header > .cost-info {
    align-items: self-end !important; }
    .sub-header > .cost-info > .inner-row {
      align-items: baseline !important; }
    .sub-header > .cost-info > .total-price-text {
      color: #666666; }

.cost-info {
  align-items: baseline;
  margin-left: auto; }
  .cost-info.inner-row {
    padding-left: 8px; }

.header-section {
  justify-content: space-between;
  width: 100%; }

.justify-with-width {
  width: 100%;
  justify-content: space-between; }

.vertical-rule {
  background-color: #d9d9d9;
  margin: 0 2.5vw;
  width: 1px; }

.mobile-horizontal-rule {
  width: 90%;
  height: 0.5px;
  background-color: #d9d9d9;
  margin-left: 6vw;
  margin-bottom: 4vw;
  margin-top: 2vw; }

.auto-fill {
  flex: auto; }

.learn-more {
  align-items: self-end;
  align-self: center;
  margin-right: 0%;
  text-decoration: underline !important; }
  .learn-more span {
    cursor: pointer; }

@media only screen and (max-width: 480px) {
  .cost-info {
    padding-right: unset; }
  .mobile-header {
    align-items: flex-start; }
  .personal-coverage,
  .zero-financial-responsibility {
    flex-direction: column; } }

.number-display-container {
  display: flex;
  flex-direction: row;
  align-self: center; }
  .number-display-container .number-display {
    color: #e5e5e5 !important; }

.promo-button {
  height: 2.875rem;
  margin: 0.7rem 0 0 1rem !important; }
  @media only screen and (max-width: 840px) {
    .promo-button {
      margin: 1rem 0 0 !important;
      width: 100% !important; } }

.footer-status-bar {
  display: flex;
  width: 100%;
  position: absolute;
  top: -50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #447e4b; }

.total-item {
  display: flex;
  flex-direction: column; }
  .total-item .total-item-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; }
    @media only screen and (max-width: 840px) {
      .total-item .total-item-row {
        display: flex;
        justify-content: space-between; } }
    .total-item .total-item-row .pricing-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      @media only screen and (max-width: 840px) {
        .total-item .total-item-row .pricing-items {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-top: -20px; } }

.additional-rental-days {
  margin-top: 12px; }

.total-subItem .total-subItem-row {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .total-subItem .total-subItem-row .pricing-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

.breakdown-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px; }
  .breakdown-header .breakdown-label {
    display: flex; }

.breakdown-subItems {
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between; }
  .breakdown-subItems .subItems-label {
    max-width: 60%; }

.expand-arrow {
  padding: 0;
  margin: -15px;
  margin-left: -12px;
  height: auto;
  width: auto; }

.estimated-total-modal-container {
  display: flex;
  width: 100%;
  flex-direction: column; }
  .estimated-total-modal-container .totals-section {
    display: flex;
    flex-direction: column; }
  .estimated-total-modal-container .totalItem {
    margin-bottom: 32px; }
  .estimated-total-modal-container .total-taxes {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding-top: 22px; }
  .estimated-total-modal-container .total-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    align-items: flex-end; }
    .estimated-total-modal-container .total-footer .points {
      margin-bottom: 8px; }
    .estimated-total-modal-container .total-footer .estimated-total {
      display: flex;
      flex-direction: row;
      align-items: flex-end; }
      .estimated-total-modal-container .total-footer .estimated-total .number-display {
        color: black !important; }

.sticky-footer {
  min-height: 150px;
  background-color: black;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .sticky-footer section {
    display: flex; }
  .sticky-footer .sticky-footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0 130px; }
  .sticky-footer .sticky-footer-sections {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between; }
  .sticky-footer #promo-section {
    align-content: center; }
  @media only screen and (max-width: 840px) {
    .sticky-footer {
      min-height: 157px; }
      .sticky-footer .sticky-footer-container {
        padding: 0 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: normal; }
      .sticky-footer .sticky-footer-sections {
        margin-bottom: 15px; }
      .sticky-footer .promo-field-container {
        margin: 21px 0 41px; }
      .sticky-footer #promo-section {
        align-content: center; } }

.border {
  height: 0.063rem;
  width: 100%;
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
  background-color: #d9d9d9;
  margin-bottom: 3.9375rem;
  margin-top: 2.4375rem; }

.location-text-field {
  width: 33%; }

.sc-audi-owner-program {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between; }
  .sc-audi-owner-program > div {
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center; }
    .sc-audi-owner-program > div > h3 {
      font-size: 14px;
      color: black;
      margin: 0;
      font-weight: 400; }
      .sc-audi-owner-program > div > h3 > span {
        display: block;
        padding-top: 8px; }
    .sc-audi-owner-program > div > img {
      width: 65px;
      margin: 0 auto; }
  .sc-audi-owner-program > button .material-icons {
    color: black !important; }
  .sc-audi-owner-program .program-name {
    margin-right: auto;
    color: #707070; }
  .sc-audi-owner-program .available-days {
    font-size: 2.25rem;
    color: black;
    margin: 0;
    font-weight: 700;
    margin-left: 0.3rem; }

@media only screen and (max-width: 480px) {
  .sc-audi-owner-program {
    align-self: flex-start;
    width: 100%; }
    .sc-audi-owner-program > div {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      margin: 0; }
      .sc-audi-owner-program > div > h3 > span {
        text-align: left;
        padding-top: 0; }
      .sc-audi-owner-program > div > img {
        width: 67px;
        height: auto;
        align-self: center;
        margin: 0 14px 0 0; }
    .sc-audi-owner-program > button {
      align-self: flex-end; }
    .sc-audi-owner-program .program-name {
      margin-left: 0; } }

.loyalty-program-modal {
  text-align: left; }
  .loyalty-program-modal > p {
    margin: 0 auto;
    font-family: sba, sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    color: #a2a2a2;
    max-width: 90%; }
  .loyalty-program-modal-options {
    border-bottom: 1px solid #d9d9d9; }
    .loyalty-program-modal-options > h3 {
      padding-top: 1.25rem; }
    .loyalty-program-modal-options > p:last-child {
      padding-bottom: 49px; }
  .loyalty-program-modal > ul {
    padding: 0; }
    .loyalty-program-modal > ul > p {
      padding-top: 10px; }
      .loyalty-program-modal > ul > p > ul > li {
        -webkit-margin-after: 0% !important;
                margin-block-end: 0% !important;
        -webkit-margin-before: 0% !important;
                margin-block-start: 0% !important; }
        .loyalty-program-modal > ul > p > ul > li:first-child {
          padding-top: 16px; }
  .loyalty-program-modal .link {
    text-decoration: underline; }
    .loyalty-program-modal .link > p {
      margin-top: 30px; }

.loyalty-program-tooltip-icon {
  color: #7b7b7b;
  font-size: inherit !important; }

.sc-loyalty-toggle {
  display: flex;
  align-items: center;
  margin-left: 90px;
  margin-top: 18px; }
  .sc-loyalty-toggle > span {
    white-space: nowrap; }
  .sc-loyalty-toggle > .loyalty-button {
    background: white;
    border: 1px solid black;
    color: #707070;
    padding: 7px;
    width: 48px;
    border-radius: 0 4px 4px 0; }
    .sc-loyalty-toggle > .loyalty-button:first-of-type {
      border-radius: 4px 0 0 4px; }
    .sc-loyalty-toggle > .loyalty-button.active, .sc-loyalty-toggle > .loyalty-button:focus {
      background-color: black;
      -webkit-transition: background-color 0.1s linear;
      transition: background-color 0.1s linear;
      color: white;
      outline: 0; }
    .sc-loyalty-toggle > .loyalty-button:hover {
      cursor: pointer; }

@media only screen and (max-width: 960px) {
  .sc-loyalty-toggle {
    margin-left: 0; }
    .sc-loyalty-toggle > span,
    .sc-loyalty-toggle > button,
    .sc-loyalty-toggle > div {
      flex: auto; } }

.loyalty-points-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 46px; }
  .loyalty-points-info-total {
    padding: 0;
    display: flex;
    margin-top: 32px; }
    .loyalty-points-info-total > span {
      margin: 0; }

@media only screen and (max-width: 960px) {
  .loyalty-points-info {
    align-items: flex-start;
    margin-left: 0; }
    .loyalty-points-info-total {
      margin-top: 10px; } }

.whats-included-desktop {
  display: flex; }

.whats-included-mobile {
  display: block; }
  .whats-included-mobile .included-services {
    display: block; }
  .whats-included-mobile > div {
    display: flex; }

.sc-vehicle-select {
  flex: 1 1;
  display: flex;
  flex-flow: column; }
  .sc-vehicle-select > header {
    display: inherit;
    align-items: center;
    justify-content: center;
    padding: 16px;
    flex: 0 0 auto;
    background: white; }
  .sc-vehicle-select .audi-owner-days {
    display: flex; }
    .sc-vehicle-select .audi-owner-days p {
      text-align: right; }
  .sc-vehicle-select .login-container {
    display: flex;
    margin-left: auto;
    justify-content: center;
    margin-bottom: 1.2rem;
    align-items: flex-end; }
    .sc-vehicle-select .login-container > .login-to-pay-textfield {
      margin-top: inherit !important; }
      .sc-vehicle-select .login-container > .login-to-pay-textfield > .login-to-pay-link {
        font-weight: 400; }
  .sc-vehicle-select > div {
    max-width: 1328px;
    width: 95vw; }
    .sc-vehicle-select > div > section {
      flex-flow: column; }
      .sc-vehicle-select > div > section.section-container {
        display: flex;
        padding: 24px 0; }
        .sc-vehicle-select > div > section.section-container > .section-header {
          width: 100%;
          display: inherit;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 2.125rem; }
          .sc-vehicle-select > div > section.section-container > .section-header div.select-model-subsection {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .sc-vehicle-select > div > section.section-container > .section-header div.select-model-subsection > .title-and-included-options {
              display: flex;
              flex-direction: column;
              justify-content: space-between; }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div {
            display: flex;
            flex-direction: row;
            margin-top: 10px; }
            .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > div {
              display: flex; }
            .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > p {
              white-space: nowrap; }
        .sc-vehicle-select > div > section.section-container > .location-date-time-fields {
          width: 100%; }

.vehicle-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto; }
  .vehicle-header > div {
    display: flex; }

@media only screen and (min-width: 960px) {
  .select-model-subsection {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .login-container {
    display: flex;
    margin-left: auto;
    justify-content: center;
    margin-bottom: 1.2rem;
    align-items: flex-end; }
    .login-container > .login-to-pay-textfield {
      margin-top: inherit !important; } }

@media only screen and (max-width: 960px) {
  .vehicle-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .vehicle-header > div {
      display: flex;
      flex-direction: column; }
      .vehicle-header > div > div {
        display: flex; }
    .vehicle-header .included-services {
      display: flex;
      flex-direction: column; }
    .vehicle-header .select-model-subsection {
      display: flex;
      flex-direction: column; }
  .program-name {
    margin-top: 36px; }
  .sc-vehicle-select > div > section.section-container {
    padding: 0 20px; }
    .sc-vehicle-select > div > section.section-container > .section-header {
      display: flex;
      flex-direction: column;
      white-space: nowrap; }
      .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div {
        display: block; }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > div {
          display: flex; }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > .included-services {
          margin-top: 0.625rem;
          display: flex;
          flex-direction: column; }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > button > span .chevron-up {
          -webkit-transform: rotate(270deg);
                  transform: rotate(270deg); }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > button > span .chevron-down {
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }
      .sc-vehicle-select > div > section.section-container > .section-header .login-container {
        display: flex;
        justify-content: flex-start;
        margin-left: 0; }
      .sc-vehicle-select > div > section.section-container > .section-header.owner-program {
        padding-bottom: 15px;
        display: flex; } }

@media only screen and (max-width: 840px) {
  .sc-vehicle-select .select-model-subsection {
    flex-direction: column !important; } }

@media only screen and (max-width: 600px) {
  .sc-vehicle-select .section-header > div > div.sc-audi-owner-program {
    align-self: flex-start; } }

@media only screen and (max-width: 480px) {
  .sc-vehicle-select > div {
    -webkit-overflow-scrolling: touch; }
    .sc-vehicle-select > div > section.section-container {
      padding: 0 20px; }
      .sc-vehicle-select > div > section.section-container > .section-header {
        display: flex;
        flex-direction: column;
        white-space: nowrap; }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div {
          display: block; }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > div {
            display: flex; }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > .included-services {
            margin-top: 0.625rem;
            display: flex;
            flex-direction: column; }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > button > span .chevron-up {
            -webkit-transform: rotate(270deg);
                    transform: rotate(270deg); }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > button > span .chevron-down {
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg); }
      .sc-vehicle-select > div > section.section-container > .location-date-time-fields.owner-program {
        display: block; } }

.booking-container {
  display: flex;
  flex-flow: column;
  flex: 1 1;
  padding-bottom: 150px; }
  .booking-container > header {
    flex: 0 0 auto;
    text-align: center; }
  .booking-container > section {
    display: flex;
    justify-content: center;
    margin: 0 auto; }
    .booking-container > section .section-container {
      align-items: center;
      flex: 0 1 auto; }
    .booking-container > section .location-section {
      margin: 1.5rem 0 0; }
    @media only screen and (min-width: 960px) {
      .booking-container > section .location-section {
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        flex-basis: auto; } }
    @media only screen and (min-width: 1280px) {
      .booking-container > section .location-section {
        grid-template-columns: repeat(4, minmax(0, 1fr)); } }
    .booking-container > section .location {
      box-sizing: border-box; }
      .booking-container > section .location :hover {
        border-radius: 4px; }
      .booking-container > section .location .address {
        color: #808080; }
      .booking-container > section .location .partner-location {
        padding-top: 0.19rem; }
      .booking-container > section .location .partner-location-info {
        color: #808080;
        font-size: 1.1rem;
        margin-right: 0.3rem;
        vertical-align: middle; }
    .booking-container > section .gray-bar {
      display: flex;
      flex: 0 0 auto;
      background-color: #f4f4f4;
      max-width: 100vw !important;
      justify-content: center; }
  .booking-container .selection-inputs {
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem; }
  .booking-container > footer {
    z-index: 10;
    flex: 0 0 auto; }

@media only screen and (max-width: 840px) {
  .booking-container > section .section-container {
    align-items: normal; } }

@media only screen and (max-width: 480px) {
  .booking-container {
    max-width: 100vw; }
    .booking-container > footer {
      flex-direction: row-reverse; } }

.promo-item-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between; }
  .promo-item-row .discount-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

.summary-total-modal-container {
  display: flex;
  width: 100%;
  flex-direction: column; }
  .summary-total-modal-container .totals-section {
    display: flex;
    flex-direction: column; }
  .summary-total-modal-container .totalItem {
    margin-bottom: 32px; }
  .summary-total-modal-container .total-taxes {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding-top: 22px; }
  .summary-total-modal-container .total-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 16px; }
    .summary-total-modal-container .total-footer .summary-total {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      margin-left: 4rem; }
      .summary-total-modal-container .total-footer .summary-total .number-display {
        color: black !important; }

.date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 38px;
  flex-wrap: wrap;
  padding-bottom: 1rem; }

.policy-section {
  width: 295px;
  padding-top: 60px;
  padding-bottom: 37px; }

.summary-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 6px; }

.soft-optional label > span {
  display: none; }

.totals-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 1rem; }
  .totals-buttons .confirm-booking-button {
    margin-right: 6px; }

.cost-summary-location-text-field {
  width: 100%; }

@media only screen and (max-width: 600px) {
  .date {
    display: flex;
    flex-direction: column;
    margin-bottom: 38px; }
  .totals-buttons {
    display: flex;
    flex-direction: column; }
    .totals-buttons .confirm-booking-button {
      margin-bottom: 1rem !important;
      margin-right: 0; }
  .stop-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px; }
  .summary-modify {
    width: auto; } }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; }

.sc-form > section {
  display: flex;
  flex-flow: column; }
  .sc-form > section > header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 225px;
    text-transform: capitalize;
    color: white;
    background: black;
    background-position: center;
    background-size: cover; }
    .sc-form > section > header.no-bg {
      flex: 1 1 150px;
      background: none;
      color: #404040; }
    .sc-form > section > header.curbside {
      display: flex;
      flex-direction: column;
      background-color: white;
      color: black;
      max-height: 140px;
      justify-content: space-evenly;
      padding-top: 60px; }
    .sc-form > section > header > .sub-header {
      text-transform: none;
      color: #696767;
      font-size: 20px;
      padding: 15px;
      font-weight: 400; }
    .sc-form > section > header.login {
      background-image: url(/static/media/login.ee0f40f1.jpg); }
    .sc-form > section > header.signup {
      background-image: url(/static/media/signup.9a2e3049.jpg); }
  .sc-form > section > .sub-header {
    font-size: 21px;
    line-height: 24px;
    color: #6a6767;
    text-align: center;
    width: 45%;
    margin: 50px auto 0;
    font-weight: 300; }
  .sc-form > section > section {
    display: flex;
    flex-flow: row; }
    .sc-form > section > section > * {
      margin-bottom: 16px; }
    .sc-form > section > section > form {
      display: flex;
      flex-flow: column;
      margin: auto;
      flex: 0 1 400px;
      margin-top: 40px; }
      .sc-form > section > section > form > div {
        text-align: left;
        width: 100% !important;
        margin: 12px 0; }
      .sc-form > section > section > form > .section-title {
        text-transform: capitalize;
        color: #adacac; }
        .sc-form > section > section > form > .section-title.padding {
          padding-top: 40px; }
      .sc-form > section > section > form > .button-link-group {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 120px;
        text-align: center; }
        .sc-form > section > section > form > .button-link-group > a {
          text-decoration: none;
          cursor: pointer;
          outline: none; }
  .sc-form > section > footer {
    display: flex;
    margin-bottom: 16px; }
    .sc-form > section > footer > div {
      margin: auto;
      flex: 0 1 400px;
      margin-top: 15px; }
      .sc-form > section > footer > div > * {
        display: block;
        padding: 8px 0; }
      .sc-form > section > footer > div p {
        margin: 8px 0; }

@media only screen and (max-width: 480px) {
  .sc-form > section > section {
    padding: 0 24px; }
  .sc-form > section > .sub-header {
    width: 85%; }
  .sc-form > section > footer {
    padding: 0 24px; } }

.join-reserve-form > form {
  margin-top: 0; }

.form-link-container {
  text-align: left !important;
  font-size: 14px !important;
  margin-top: 15px !important;
  margin-bottom: 0 !important;
  color: #707070; }
  .form-link-container > a {
    color: #707070 !important; }

@media only screen and (max-width: 480px) {
  .policy-section {
    width: auto; } }

.dialog-split .MuiPaper-root {
  background: -webkit-gradient(linear, left top, right top, from(#f2f2f2), color-stop(50%, #f2f2f2), color-stop(50%, white), to(white));
  background: -webkit-linear-gradient(left, #f2f2f2 0%, #f2f2f2 50%, white 50%, white 100%);
  background: linear-gradient(to right, #f2f2f2 0%, #f2f2f2 50%, white 50%, white 100%); }

.dialog-split .dialogHeader {
  display: flex;
  justify-content: flex-end; }

.dialog-container {
  display: flex; }

.loggedIn-summary-container {
  width: 100%; }

.loggedOut-summary-container {
  width: 45%;
  padding-right: 50px; }

.join-container {
  margin-top: 19px;
  margin-left: 64px;
  margin-right: 50px;
  width: 45%; }

@media only screen and (max-width: 480px) {
  .dialog-split .MuiPaper-root {
    background: white; }
  .dialog-container {
    display: flex;
    flex-direction: column; }
  .loggedOut-summary-container {
    width: 100%;
    padding-right: 0; }
  .loggedIn-container {
    width: 100%; }
  .join-container {
    margin-left: 0;
    margin-right: 0;
    margin-top: 48px;
    width: 100%; } }

.cost-details-header {
  margin: 0 1.5rem !important; }

.confirm-booking-date-time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px; }

@media only screen and (max-width: 840px) {
  .confirm-booking-date-time {
    margin-bottom: 1.25rem; } }

.date-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.confirm-booking-points {
  display: flex;
  flex-direction: row;
  align-items: flex-end; }

.points-text {
  white-space: nowrap; }

@media only screen and (max-width: 840px) {
  .points-total {
    margin-bottom: 1.25rem !important; } }

.insurance-price {
  padding-left: 5px; }

@media only screen and (min-width: 840px) {
  .audi-protection-confirmation {
    margin-left: 24px; } }

@media only screen and (max-width: 600px) {
  .request-pick-up-delivery-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 10.5rem; } }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Book.ttf") format("truetype");
  font-weight: 100 400;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BookItalic.ttf") format("truetype");
  font-weight: 100 400;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Black.ttf") format("truetype");
  font-weight: 700 900;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BlackItalic.ttf") format("truetype");
  font-weight: 700 900;
  font-style: italic; }

@font-face {
  font-family: silvercar;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("silvercar"), url("/assets/fonts/sc-icons.eot"), url("/assets/fonts/sc-icons.eot?") format("embedded-opentype"), url("/assets/fonts/sc-icons.woff") format("woff"), url("/assets/fonts/sc-icons.ttf") format("truetype"), url("/assets/fonts/sc-icons.svg") format("svg"); }

@font-face {
  font-family: QuatroBold;
  src: url("/assets/fonts/Quatro-Bold.ttf");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: AudiType-ExtendedNormal;
  src: url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff") format("woff"), url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: AudiType-Variable;
  src: url("/assets/fonts/AudiTypeVF_4.04.ttf") format("truetype"), url("/assets/fonts/AudiTypeVF_4.04.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, and/or their suppliers. You may not
attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com
*/
@font-face {
  font-family: BentonSansComp;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("BentonSansComp"), url("/assets/fonts/BentonSansCompMedium.eot"), url("/assets/fonts/BentonSansCompMedium.eot?") format("embedded-opentype"), url("/assets/fonts/BentonSansCompMedium.woff") format("woff"), url("/assets/fonts/BentonSansCompMedium.ttf") format("truetype"), url("/assets/fonts/BentonSansCompMedium.svg#web") format("svg"); }

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font: 400 normal normal 14px/1 silvercar, sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-size: inherit;
  text-rendering: auto;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-building::before {
  content: "\e911"; }

.icon-airplane::before {
  content: "\e600"; }

.icon-arrow::before {
  content: "\e601"; }

.icon-calendar_::before {
  content: "\e602"; }

.icon-calendar_reservation::before {
  content: "\e603"; }

.icon-calendar_x::before {
  content: "\e604"; }

.icon-calendar::before {
  content: "\e605"; }

.icon-check_mark_in_circle::before {
  content: "\e606"; }

.icon-clock::before {
  content: "\e607"; }

.icon-facebook_f::before {
  content: "\e608"; }

.icon-facebook::before {
  content: "\e609"; }

.icon-google_g::before {
  content: "\e60a"; }

.icon-instagram::before {
  content: "\e60b"; }

.icon-marketing_site_arrows::before {
  content: "\e60c"; }

.icon-marketing_site_bell::before {
  content: "\e60d"; }

.icon-marketing_site_check-mark::before,
.icon-check-mark::before {
  content: "\e60e"; }

.icon-marketing_site_phone::before {
  content: "\e60f"; }

.icon-marketing_site_wallet::before {
  content: "\e610"; }

.icon-narrow_arrow::before {
  content: "\e611"; }

.icon-navicon::before {
  content: "\e612"; }

.icon-navigation_account::before {
  content: "\e613"; }

.icon-navigation_contact_us::before {
  content: "\e614"; }

.icon-navigation_home::before {
  content: "\e615"; }

.icon-navigation_inbox::before {
  content: "\e616"; }

.icon-navigation_locations::before {
  content: "\e617"; }

.icon-navigation_refer_a_friend::before {
  content: "\e618"; }

.icon-navigation_reservations::before {
  content: "\e619"; }

.icon-navigation_reserve_a_car::before {
  content: "\e61a"; }

.icon-twitter::before {
  content: "\e61b"; }

.icon-arrow_left::before {
  content: "\e900"; }

.icon-arrow_right::before {
  content: "\e901"; }

.icon-arrow_up::before {
  content: "\e902"; }

.icon-navigation_blog::before {
  content: "\e903"; }

.icon-navigation_booked_third_party::before {
  content: "\e904"; }

.icon-navigation_how_it_works::before {
  content: "\e905"; }

.icon-navigation_log_in::before {
  content: "\e906"; }

.icon-navigation_log_out {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }
  .icon-navigation_log_out::before {
    content: "\e906"; }

.icon-arrow-left::before,
.icon-narrow_arrow_left::before {
  content: "\e907"; }

.icon-arrow-right::before,
.icon-narrow_arrow_right::before {
  content: "\e908"; }

.icon-narrow_arrow-up::before {
  content: "\e909"; }

.icon-narrow_arrow-down::before {
  content: "\e912"; }

.icon-close::before,
.icon-narrow_close::before {
  content: "\e90a"; }

.icon-navigation_policies::before {
  content: "\e90b"; }

.icon-navigation_sign_up::before {
  content: "\e90c"; }

.icon-tips_and_tricks {
  content: "\e90d"; }

.icon-EmergencyIcon::before {
  content: "\e90e"; }

.icon-QuestionIcon::before {
  content: "\e90f"; }

.icon-ReservationsIcon::before {
  content: "\e910"; }

.reservation-menu-item {
  position: relative;
  width: 100%;
  left: 1rem; }

.reservation-menu-text {
  font-family: AudiType-Variable, sans-serif !important;
  font-size: 1rem !important;
  color: #333333 !important;
  height: 2.5rem; }

.manage-reservation-section {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #666666;
  white-space: nowrap; }

@media only screen and (max-width: 600px) {
  .manage-reservations-container {
    width: 90%; }
  .manage-reservation-section {
    display: block; } }

.confirmed-booking-container {
  background-color: #f2f2f2;
  padding: 50px;
  position: relative;
  height: 50%;
  width: 100%;
  overflow-y: unset; }

.booking-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row; }

.subheader {
  display: flex;
  align-items: flex-end;
  margin-bottom: 66px; }

.booking-border {
  height: 0.063rem;
  width: 100%;
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
  background-color: #d9d9d9; }

.booking-details-img {
  position: absolute;
  top: 42.75rem;
  right: 3.125rem;
  height: 18.327rem;
  width: auto; }

.booking-details-footer {
  display: flex;
  justify-content: space-between; }

.booking-details-first-column,
.booking-details-second-column,
.booking-details-third-column {
  display: flex;
  flex-direction: column; }

@media only screen and (max-width: 840px) {
  .confirmed-booking-container {
    height: 75%;
    padding: 1rem 1rem 2rem;
    width: 100%; }
  .booking-details {
    flex-direction: column; }
  .booking-details-img {
    top: 57.5rem;
    height: 10.371rem; }
  .subheader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1.875rem; }
  .booking-border {
    margin: 1.25rem 0; } }

@media only screen and (max-width: 600px) {
  .confirmed-booking-container {
    height: 75%;
    padding: 1rem 1rem 2rem;
    width: 100%; }
  .booking-details {
    flex-direction: column; }
  .booking-details-img {
    top: 57.5rem;
    height: 10.371rem; }
  .subheader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1.875rem; }
  .booking-border {
    margin: 1.25rem 0; } }

.app-booking {
  display: flex;
  align-items: stretch;
  align-content: center;
  margin-top: 8rem; }

.app-screen-container {
  max-width: 80%;
  margin: 0 19rem 12rem 12rem;
  padding: 6.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around; }

.app-screen-image {
  position: relative; }

.app-screen {
  height: 35.313rem;
  position: absolute;
  width: 16.438rem;
  margin-top: 0.688rem;
  margin-left: 0.75rem; }

.app-iphone {
  position: absolute;
  height: 36.688rem;
  width: 18rem; }

.app-screen-text {
  width: 31.25rem;
  margin-top: 6rem; }

.app-screen-text-image {
  display: flex;
  flex-direction: row; }

.app-store {
  margin-left: 0.875rem; }

@media only screen and (max-width: 840px) {
  .app-booking {
    margin-right: 3rem;
    margin-top: 60px; }
  .app-screen-container {
    margin: 0;
    padding: 0 1.25rem 4rem; }
  .app-screen-image {
    display: none; }
  .app-screen-text {
    width: auto;
    margin-top: 20px; } }

@media only screen and (max-width: 600px) {
  .app-booking {
    margin-right: 3rem;
    margin-top: 0; }
  .app-screen-container {
    margin: 0;
    padding: 0 1.25rem 4rem; }
  .app-screen-image {
    display: none; }
  .app-screen-text {
    width: auto;
    margin-top: 0; } }

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  /* added line */ }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Book.ttf") format("truetype");
  font-weight: 100 400;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BookItalic.ttf") format("truetype");
  font-weight: 100 400;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Black.ttf") format("truetype");
  font-weight: 700 900;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BlackItalic.ttf") format("truetype");
  font-weight: 700 900;
  font-style: italic; }

@font-face {
  font-family: silvercar;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("silvercar"), url("/assets/fonts/sc-icons.eot"), url("/assets/fonts/sc-icons.eot?") format("embedded-opentype"), url("/assets/fonts/sc-icons.woff") format("woff"), url("/assets/fonts/sc-icons.ttf") format("truetype"), url("/assets/fonts/sc-icons.svg") format("svg"); }

@font-face {
  font-family: QuatroBold;
  src: url("/assets/fonts/Quatro-Bold.ttf");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: AudiType-ExtendedNormal;
  src: url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff") format("woff"), url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: AudiType-Variable;
  src: url("/assets/fonts/AudiTypeVF_4.04.ttf") format("truetype"), url("/assets/fonts/AudiTypeVF_4.04.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, and/or their suppliers. You may not
attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com
*/
@font-face {
  font-family: BentonSansComp;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("BentonSansComp"), url("/assets/fonts/BentonSansCompMedium.eot"), url("/assets/fonts/BentonSansCompMedium.eot?") format("embedded-opentype"), url("/assets/fonts/BentonSansCompMedium.woff") format("woff"), url("/assets/fonts/BentonSansCompMedium.ttf") format("truetype"), url("/assets/fonts/BentonSansCompMedium.svg#web") format("svg"); }

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font: 400 normal normal 14px/1 silvercar, sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-size: inherit;
  text-rendering: auto;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-building::before {
  content: "\e911"; }

.icon-airplane::before {
  content: "\e600"; }

.icon-arrow::before {
  content: "\e601"; }

.icon-calendar_::before {
  content: "\e602"; }

.icon-calendar_reservation::before {
  content: "\e603"; }

.icon-calendar_x::before {
  content: "\e604"; }

.icon-calendar::before {
  content: "\e605"; }

.icon-check_mark_in_circle::before {
  content: "\e606"; }

.icon-clock::before {
  content: "\e607"; }

.icon-facebook_f::before {
  content: "\e608"; }

.icon-facebook::before {
  content: "\e609"; }

.icon-google_g::before {
  content: "\e60a"; }

.icon-instagram::before {
  content: "\e60b"; }

.icon-marketing_site_arrows::before {
  content: "\e60c"; }

.icon-marketing_site_bell::before {
  content: "\e60d"; }

.icon-marketing_site_check-mark::before,
.icon-check-mark::before {
  content: "\e60e"; }

.icon-marketing_site_phone::before {
  content: "\e60f"; }

.icon-marketing_site_wallet::before {
  content: "\e610"; }

.icon-narrow_arrow::before {
  content: "\e611"; }

.icon-navicon::before {
  content: "\e612"; }

.icon-navigation_account::before {
  content: "\e613"; }

.icon-navigation_contact_us::before {
  content: "\e614"; }

.icon-navigation_home::before {
  content: "\e615"; }

.icon-navigation_inbox::before {
  content: "\e616"; }

.icon-navigation_locations::before {
  content: "\e617"; }

.icon-navigation_refer_a_friend::before {
  content: "\e618"; }

.icon-navigation_reservations::before {
  content: "\e619"; }

.icon-navigation_reserve_a_car::before {
  content: "\e61a"; }

.icon-twitter::before {
  content: "\e61b"; }

.icon-arrow_left::before {
  content: "\e900"; }

.icon-arrow_right::before {
  content: "\e901"; }

.icon-arrow_up::before {
  content: "\e902"; }

.icon-navigation_blog::before {
  content: "\e903"; }

.icon-navigation_booked_third_party::before {
  content: "\e904"; }

.icon-navigation_how_it_works::before {
  content: "\e905"; }

.icon-navigation_log_in::before {
  content: "\e906"; }

.icon-navigation_log_out {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }
  .icon-navigation_log_out::before {
    content: "\e906"; }

.icon-arrow-left::before,
.icon-narrow_arrow_left::before {
  content: "\e907"; }

.icon-arrow-right::before,
.icon-narrow_arrow_right::before {
  content: "\e908"; }

.icon-narrow_arrow-up::before {
  content: "\e909"; }

.icon-narrow_arrow-down::before {
  content: "\e912"; }

.icon-close::before,
.icon-narrow_close::before {
  content: "\e90a"; }

.icon-navigation_policies::before {
  content: "\e90b"; }

.icon-navigation_sign_up::before {
  content: "\e90c"; }

.icon-tips_and_tricks {
  content: "\e90d"; }

.icon-EmergencyIcon::before {
  content: "\e90e"; }

.icon-QuestionIcon::before {
  content: "\e90f"; }

.icon-ReservationsIcon::before {
  content: "\e910"; }

.global-nav::after {
  display: block;
  content: attr(title);
  font-weight: 600;
  height: 0.0625rem;
  color: transparent;
  overflow: hidden;
  visibility: hidden; }

.global-nav:hover,
.global-nav.open {
  font-weight: 600;
  letter-spacing: 0;
  border-bottom: 0.25rem solid black;
  padding-bottom: 0.5rem; }

.header-outer-box {
  display: flex;
  justify-content: center; }

.header-inner-box {
  display: flex;
  flex: 0 1 90rem;
  margin: 1rem 0 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  max-width: 1328px; }

.audi-logo-container {
  align-self: flex-start;
  margin-top: 1.7rem; }

.audi-logo {
  height: 1.7rem; }

.audi-text-outer {
  margin-right: auto;
  margin-top: 1.7rem; }

.audi-on-demand {
  color: black !important;
  font-family: AudiType-ExtendedNormal, sans-serif !important; }

.audi-text-outer-logged-in {
  margin-right: auto;
  margin-top: 1.7rem; }

.audi-owners {
  top: 2rem;
  width: 8rem;
  margin-top: 1.7rem; }

.nav-login {
  top: 2rem;
  text-decoration: none;
  width: 4rem;
  margin-top: 1.7rem; }

.nav-account-dropdown {
  margin-top: 0.7rem;
  text-decoration: none; }

.grey-font {
  color: #666666 !important; }

.booking {
  align-self: flex-end;
  flex-wrap: nowrap; }

.no-text-decoration {
  -webkit-text-decoration: 'none' !important;
          text-decoration: 'none' !important; }

.sc-rewards-item.loyalty-points, .sc-rewards-item.audi-days {
  padding: 0 1rem;
  margin: 0;
  background-color: #f2f2f2;
  text-decoration: none; }
  .sc-rewards-item.loyalty-points > span.global-nav, .sc-rewards-item.audi-days > span.global-nav {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0.5rem;
    margin-bottom: 0.25rem;
    display: block; }
  .sc-rewards-item.loyalty-points > span, .sc-rewards-item.audi-days > span {
    font-size: 18px; }

.points-text-with-numbers {
  display: flex;
  position: relative;
  align-items: center;
  top: -1rem;
  grid-gap: 7rem;
  gap: 7rem;
  padding: 2rem 0;
  height: 3.5rem;
  bottom: 1rem;
  justify-content: space-between;
  width: 14rem;
  padding-right: 2rem; }

.always-audi-days-container {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  top: -0.8rem;
  padding-top: 1rem !important;
  bottom: 1rem;
  width: 14rem;
  padding-right: 2rem; }

.always-audi-days-outer-container {
  height: auto;
  top: -1.75rem;
  display: flex;
  position: relative; }

.horizontal-rule-dropdown {
  width: 188px;
  position: relative;
  background-color: #d9d9d9 !important;
  color: #d9d9d9 !important;
  height: 1px;
  right: 2px;
  left: 2px;
  margin-bottom: 6px;
  margin-top: 6px; }

.row-flexbox {
  flex-direction: row; }

.column-flexbox {
  flex-direction: column;
  width: 100%; }

.numbers {
  padding-right: 2rem;
  justify-content: flex-end; }

.points-text {
  font-family: AudiType-Variable, sans-serif !important;
  color: #333333 !important; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Book.ttf") format("truetype");
  font-weight: 100 400;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BookItalic.ttf") format("truetype");
  font-weight: 100 400;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Black.ttf") format("truetype");
  font-weight: 700 900;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BlackItalic.ttf") format("truetype");
  font-weight: 700 900;
  font-style: italic; }

@font-face {
  font-family: silvercar;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("silvercar"), url("/assets/fonts/sc-icons.eot"), url("/assets/fonts/sc-icons.eot?") format("embedded-opentype"), url("/assets/fonts/sc-icons.woff") format("woff"), url("/assets/fonts/sc-icons.ttf") format("truetype"), url("/assets/fonts/sc-icons.svg") format("svg"); }

@font-face {
  font-family: QuatroBold;
  src: url("/assets/fonts/Quatro-Bold.ttf");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: AudiType-ExtendedNormal;
  src: url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff") format("woff"), url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: AudiType-Variable;
  src: url("/assets/fonts/AudiTypeVF_4.04.ttf") format("truetype"), url("/assets/fonts/AudiTypeVF_4.04.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, and/or their suppliers. You may not
attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com
*/
@font-face {
  font-family: BentonSansComp;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("BentonSansComp"), url("/assets/fonts/BentonSansCompMedium.eot"), url("/assets/fonts/BentonSansCompMedium.eot?") format("embedded-opentype"), url("/assets/fonts/BentonSansCompMedium.woff") format("woff"), url("/assets/fonts/BentonSansCompMedium.ttf") format("truetype"), url("/assets/fonts/BentonSansCompMedium.svg#web") format("svg"); }

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font: 400 normal normal 14px/1 silvercar, sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-size: inherit;
  text-rendering: auto;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-building::before {
  content: "\e911"; }

.icon-airplane::before {
  content: "\e600"; }

.icon-arrow::before {
  content: "\e601"; }

.icon-calendar_::before {
  content: "\e602"; }

.icon-calendar_reservation::before {
  content: "\e603"; }

.icon-calendar_x::before {
  content: "\e604"; }

.icon-calendar::before {
  content: "\e605"; }

.icon-check_mark_in_circle::before {
  content: "\e606"; }

.icon-clock::before {
  content: "\e607"; }

.icon-facebook_f::before {
  content: "\e608"; }

.icon-facebook::before {
  content: "\e609"; }

.icon-google_g::before {
  content: "\e60a"; }

.icon-instagram::before {
  content: "\e60b"; }

.icon-marketing_site_arrows::before {
  content: "\e60c"; }

.icon-marketing_site_bell::before {
  content: "\e60d"; }

.icon-marketing_site_check-mark::before,
.icon-check-mark::before {
  content: "\e60e"; }

.icon-marketing_site_phone::before {
  content: "\e60f"; }

.icon-marketing_site_wallet::before {
  content: "\e610"; }

.icon-narrow_arrow::before {
  content: "\e611"; }

.icon-navicon::before {
  content: "\e612"; }

.icon-navigation_account::before {
  content: "\e613"; }

.icon-navigation_contact_us::before {
  content: "\e614"; }

.icon-navigation_home::before {
  content: "\e615"; }

.icon-navigation_inbox::before {
  content: "\e616"; }

.icon-navigation_locations::before {
  content: "\e617"; }

.icon-navigation_refer_a_friend::before {
  content: "\e618"; }

.icon-navigation_reservations::before {
  content: "\e619"; }

.icon-navigation_reserve_a_car::before {
  content: "\e61a"; }

.icon-twitter::before {
  content: "\e61b"; }

.icon-arrow_left::before {
  content: "\e900"; }

.icon-arrow_right::before {
  content: "\e901"; }

.icon-arrow_up::before {
  content: "\e902"; }

.icon-navigation_blog::before {
  content: "\e903"; }

.icon-navigation_booked_third_party::before {
  content: "\e904"; }

.icon-navigation_how_it_works::before {
  content: "\e905"; }

.icon-navigation_log_in::before {
  content: "\e906"; }

.icon-navigation_log_out {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }
  .icon-navigation_log_out::before {
    content: "\e906"; }

.icon-arrow-left::before,
.icon-narrow_arrow_left::before {
  content: "\e907"; }

.icon-arrow-right::before,
.icon-narrow_arrow_right::before {
  content: "\e908"; }

.icon-narrow_arrow-up::before {
  content: "\e909"; }

.icon-narrow_arrow-down::before {
  content: "\e912"; }

.icon-close::before,
.icon-narrow_close::before {
  content: "\e90a"; }

.icon-navigation_policies::before {
  content: "\e90b"; }

.icon-navigation_sign_up::before {
  content: "\e90c"; }

.icon-tips_and_tricks {
  content: "\e90d"; }

.icon-EmergencyIcon::before {
  content: "\e90e"; }

.icon-QuestionIcon::before {
  content: "\e90f"; }

.icon-ReservationsIcon::before {
  content: "\e910"; }

.nav-menu-item {
  position: relative;
  width: 100%;
  left: 1rem; }

.nav-menu-text {
  font-family: AudiType-Variable, sans-serif !important;
  font-size: 1rem !important;
  color: #333333 !important;
  height: 2.5rem; }

.login-form-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

@media only screen and (max-width: 480px) {
  .login-form-links {
    flex-direction: column; } }

@media only screen and (max-width: 480px) {
  .set-password-form {
    margin: 6.25rem 0 !important; } }

.sc-reservation-list {
  justify-content: center; }
  .sc-reservation-list > div {
    flex: 0 1 1328px; }
    .sc-reservation-list > div > section {
      margin-bottom: 48px; }
      .sc-reservation-list > div > section > header {
        flex: 1 1;
        padding: 16px 0;
        font-size: 2em;
        font-family: sba, sans-serif; }

.container {
  margin: 24px 12px 92px; }

.tabGroup {
  margin-top: 60px; }

.upcomingRentalContainer {
  background: #f2f2f2;
  padding: 20px; }

@media only screen and (max-width: 480px) {
  .sc-reservation-list > div {
    margin: 0; }
    .sc-reservation-list > div > section {
      margin-bottom: -24px; }
      .sc-reservation-list > div > section > header {
        padding: 24px; }
  .title {
    margin-left: 10px; }
  .rentalContainer {
    background: #f2f2f2;
    padding-bottom: 24px; } }

@media only screen and (max-width: 480px) {
  .sc-reservation-list > div {
    margin: 0; }
  .sc-reservation-list > div > section {
    margin-bottom: -24px; }
  .sc-reservation-list > div > section > header {
    padding: 24px; }
  .title {
    margin-left: 10px; } }

.sc-booking {
  padding: 24px;
  display: flex;
  flex: 1 1;
  background: #f2f2f2;
  margin-bottom: 24px;
  text-decoration: none;
  flex-wrap: wrap-reverse; }
  .sc-booking .bookNow {
    max-height: 480px;
    min-height: 266px;
    min-width: 356px;
    flex-direction: column;
    display: flex;
    flex: 1 1;
    background-color: white;
    padding: 50px;
    justify-content: center; }
  .sc-booking .title {
    padding-bottom: 12px; }
  .sc-booking .description {
    padding-bottom: 33px; }
  .sc-booking.empty > aside {
    flex: 1 1; }
  .sc-booking.empty > section {
    flex: 0 0 300px; }
  .sc-booking > aside {
    display: flex;
    flex: 0 1 200px; }
    .sc-booking > aside > figure {
      flex: 1 1;
      margin: 0;
      padding: 0;
      max-height: 480px;
      min-height: 266px;
      min-width: 356px;
      display: flex;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
  .sc-booking > section {
    display: flex;
    flex: 1 1;
    flex-flow: column;
    padding-right: 24px; }
    .sc-booking > section > header {
      color: black; }
      .sc-booking > section > header > header {
        font-size: 1.571em; }
      .sc-booking > section > header > footer {
        padding: 4px 0; }
    .sc-booking > section > section {
      display: flex;
      flex-flow: column;
      color: #333333;
      font-size: 1.1em; }
      .sc-booking > section > section > * {
        padding-top: 16px; }
      .sc-booking > section > section > div {
        display: flex;
        flex-flow: row wrap; }
        .sc-booking > section > section > div > section {
          display: flex;
          flex: 1 1 50%;
          flex-flow: column;
          padding: 8px 0;
          margin-bottom: 8px; }
          .sc-booking > section > section > div > section > div + div {
            padding-top: 8px;
            font-weight: 700;
            color: black; }
      .sc-booking > section > section > footer {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .sc-booking > section > section > footer > a > button {
          margin-right: 2rem; }
        .sc-booking > section > section > footer > button + button {
          margin-left: 16px !important; }
        .sc-booking > section > section > footer > .sc-link {
          text-decoration: none;
          color: black;
          position: relative;
          font-size: 14px;
          display: flex;
          margin-left: 15px; }
          .sc-booking > section > section > footer > .sc-link::before, .sc-booking > section > section > footer > .sc-link::after {
            content: '';
            position: absolute;
            background-color: black;
            width: 10px;
            height: 2px;
            left: -15px;
            top: 7px; }
          .sc-booking > section > section > footer > .sc-link::after {
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg); }
          .sc-booking > section > section > footer > .sc-link > span {
            color: #333333;
            margin-left: 3px; }

@media only screen and (max-width: 480px) {
  .sc-booking {
    padding: 24px 10px 0;
    flex-flow: column-reverse; }
    .sc-booking.empty > section {
      flex: 0 1 300px; }
    .sc-booking > section {
      padding: 24px; }
      .sc-booking > section > section > footer {
        display: inline-block; }
        .sc-booking > section > section > footer a {
          width: 100%; }
        .sc-booking > section > section > footer .sc-link {
          padding-top: 16px; }
          .sc-booking > section > section > footer .sc-link::before, .sc-booking > section > section > footer .sc-link::after {
            margin-top: 16px; }
    .sc-booking .bookNow {
      padding: 0; }
    .sc-booking .title {
      padding-left: 29px; }
    .sc-booking .description {
      padding-left: 29px; }
    .sc-booking .bookNowButton {
      width: 106px;
      height: 44px;
      padding: 0;
      margin-left: 29px; } }

.spent-loyalty-points-modal {
  padding: 0 70px 50px;
  text-align: center; }
  .spent-loyalty-points-modal > h2 {
    text-align: center;
    font-family: sba, sans-serif;
    font-size: 42px;
    font-weight: 500;
    color: #404040;
    text-transform: capitalize; }
  .spent-loyalty-points-modal > p {
    margin: 0 auto;
    font-family: sba, sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    color: #a2a2a2;
    max-width: 75%;
    margin-bottom: 24px; }
  .spent-loyalty-points-modal > .button {
    box-sizing: border-box;
    display: inline-block;
    font-family: sba, sans-serif;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    padding: 0 24px;
    height: 36px;
    line-height: 36px;
    min-width: 88px;
    color: white;
    border-radius: 18px;
    background-color: black;
    text-transform: capitalize;
    font-weight: 500; }

@media only screen and (max-width: 480px) {
  .spent-loyalty-points-modal {
    padding: 0 16px 34px; } }

.reservation-review-modal-container {
  display: flex;
  width: 100%;
  flex-direction: column; }
  .reservation-review-modal-container .totals-section {
    display: flex;
    flex-direction: column; }
  .reservation-review-modal-container .totalItem {
    margin-bottom: 32px; }
  .reservation-review-modal-container .total-taxes {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding-top: 22px; }
  .reservation-review-modal-container .total-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 16px; }
    .reservation-review-modal-container .total-footer .misc-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 8px; }
    .reservation-review-modal-container .total-footer .summary-total {
      display: flex;
      flex-direction: row;
      align-items: flex-end; }
      .reservation-review-modal-container .total-footer .summary-total .number-display {
        color: black !important; }

@media only screen and (max-width: 480px) {
  .stop-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px; } }

@media only screen and (max-width: 840px) {
  .reservation-review-modal-container .total-footer {
    flex-direction: column; }
    .reservation-review-modal-container .total-footer .summary-total {
      align-self: flex-end; } }

.pastReservationSection {
  background: #f2f2f2;
  padding: 50px; }
  .pastReservationSection th {
    border-top: 0 !important; }
  .pastReservationSection tbody {
    border-top: 0 !important; }
  .pastReservationSection tr:last-child td {
    border-bottom: 0 !important; }
  .pastReservationSection .paginationControl {
    display: flex;
    flex-direction: row;
    justify-content: center; }

.today {
  margin-top: 20px;
  padding: 0.5rem; }

.today > h2 {
  margin-left: 1.4rem; }

@media screen and (min-width: 481px) {
  .today > h2 {
    margin-left: 0; } }

.reservation_container {
  display: grid; }
  .reservation_container.active {
    border: 1px solid #e5e5e5;
    padding: 40px; }
  .reservation_container.upcoming {
    background: white;
    padding: 20px; }

@media screen and (min-width: 0) {
  .reservation_container {
    grid-row-gap: 24px;
    grid-template-columns: 1fr 1fr; }
    .reservation_container.active {
      grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr;
      grid-template-areas: "location_name location_name" "start_container stop_container" "vehicle_info_container vehicle_info_container" "confirmation_token confirmation_token" "dealership_contact dealership_contact" "dealership_hours dealership_hours" "current_booking_car_image current_booking_car_image"; }
    .reservation_container.upcoming {
      grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 1fr 0.2fr;
      grid-template-areas: "location_name location_name" "start_container stop_container" "vehicle_info_container vehicle_info_container" "estimated_container estimated_container" "confirmation_token confirmation_token" "current_booking_car_image current_booking_car_image" "reservation_actions reservation_actions"; }
  .reservation_actions {
    display: block;
    text-align: center; }
  .view_details_action {
    margin-bottom: 27px; } }

@media screen and (min-width: 900px) {
  .today {
    padding: 0; }
  .reservation_container {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: 2fr 2fr 3fr 5fr; }
    .reservation_container.active {
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas: "location_name location_name location_name current_booking_car_image" "start_container stop_container vehicle_info_container current_booking_car_image" "confirmation_token dealership_contact dealership_hours current_booking_car_image"; }
    .reservation_container.upcoming {
      grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr 0.1fr;
      grid-template-areas: "location_name location_name location_name current_booking_car_image" "start_container stop_container vehicle_info_container current_booking_car_image" "estimated_container confirmation_token . current_booking_car_image" "reservation_actions reservation_actions . current_booking_car_image"; }
  .reservation_actions {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .view_details_action {
    margin-bottom: 0; } }

.reservation_container,
.active,
.upcoming section p:nth-child(2) {
  margin-top: 3px; }

.location_name {
  grid-area: location_name; }

.city_state {
  grid-area: city_state; }

.start_day {
  grid-area: start_day; }

.stop_day {
  grid-area: stop_day; }

.vehicle_model {
  grid-area: vehicle_model; }

.current_booking_car_image {
  margin: auto;
  grid-area: current_booking_car_image; }

.current_booking_car_image img {
  width: 100%; }

.confirmation_token {
  grid-area: confirmation_token; }

.dealership_info {
  grid-area: dealership_info; }

.dealership_contact {
  grid-area: dealership_contact; }

.dealership_hours {
  grid-area: dealership_hours; }

.reservation_actions {
  grid-area: reservation_actions; }

.view_details_action {
  grid-area: view_details_action; }

.request_extras_action {
  grid-area: request_extras_action; }

.dealership_phone {
  margin-top: 20px !important; }

.dealership_day_times {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 218px; }

.estimated_container {
  grid-area: estimated_container; }

.sc-reservations {
  display: flex;
  flex: 1 1;
  flex-flow: column; }
  .sc-reservations > div {
    display: flex;
    flex: 1 1; }
    .sc-reservations > div.row {
      display: flex;
      flex: 1 1;
      justify-content: center;
      min-height: -webkit-min-content;
      min-height: -moz-min-content;
      min-height: min-content; }
      .sc-reservations > div.row > div {
        display: flex;
        flex: 0 1 1328px;
        padding: 0 24px; }
        .sc-reservations > div.row > div > section {
          display: flex;
          flex-flow: row;
          flex: 1 1; }

@media only screen and (max-width: 480px) {
  .sc-reservations > div.row > div {
    padding: 0; } }

.flight-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px; }
  .flight-details > div.form {
    width: 100%;
    padding-right: 1.25rem; }
  .flight-details > div.submit {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 1.5rem; }

@media only screen and (max-width: 480px) {
  .flight-details {
    flex-direction: column; } }

.sc-list {
  padding-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;
  margin: 10px 0; }
  .sc-list.card {
    border-bottom: 0; }
  .sc-list header {
    color: #333333;
    font-size: 11px;
    margin: 15px 12px;
    font-weight: 500; }
  .sc-list.heavy-padding {
    margin-top: 25px; }
    .sc-list.heavy-padding > .sc-list-item {
      padding: 25px 12px; }
  .sc-list.border-top {
    border-top: 1px solid #d9d9d9; }

.sc-list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px;
  font-size: 16px;
  font-weight: 300; }
  .sc-list-item > div:first-child {
    color: #333333; }
  .sc-list-item > div:last-child {
    color: black;
    font-weight: 500; }

.sc-address {
  text-decoration: none; }
  .sc-address:hover {
    text-decoration: underline; }
  .sc-address > address {
    display: flex;
    font-style: normal;
    font-size: 14px;
    line-height: 1.5em;
    color: black; }
    .sc-address > address > div > span > i.material-icons {
      font-size: 16px;
      padding-right: 4px;
      color: black; }

.single-reservation {
  display: flex;
  flex-flow: column;
  flex: 1 1;
  align-self: center;
  margin: 0 1.5rem;
  background: #f2f2f2;
  max-width: 1328px; }
  .single-reservation > header > h2 {
    padding: 16px 0;
    margin: 0;
    text-transform: capitalize; }
  .single-reservation .location-description {
    display: flex;
    align-content: baseline; }
    .single-reservation .location-description .material-icons {
      padding-right: 4px;
      font-size: 16px; }
  .single-reservation > div > div {
    display: flex;
    flex-direction: column; }
    .single-reservation > div > div > header {
      background-color: #a3a3a3;
      padding: 100px 0 25px 35px;
      height: 20%;
      color: white;
      background-size: cover;
      background-position: center; }
      .single-reservation > div > div > header h2,
      .single-reservation > div > div > header p {
        margin: 0; }
    .single-reservation > div > div > div {
      display: flex;
      justify-content: space-between;
      padding: 24px; }
      .single-reservation > div > div > div > aside {
        align-self: flex-start;
        padding: 24px;
        flex: 1 1 25%;
        margin-right: 24px; }
        .single-reservation > div > div > div > aside.card > .sc-link {
          text-decoration: none;
          color: black;
          position: relative;
          font-size: 14px;
          display: flex;
          margin-left: 15px;
          margin: 16px 0 0 18px; }
          .single-reservation > div > div > div > aside.card > .sc-link::before, .single-reservation > div > div > div > aside.card > .sc-link::after {
            content: '';
            position: absolute;
            background-color: black;
            width: 10px;
            height: 2px;
            left: -15px;
            top: 7px; }
          .single-reservation > div > div > div > aside.card > .sc-link::after {
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg); }
          .single-reservation > div > div > div > aside.card > .sc-link > span {
            color: #333333;
            margin-left: 3px; }
        .single-reservation > div > div > div > aside > div {
          font-size: 22px;
          margin-bottom: 16px; }
          .single-reservation > div > div > div > aside > div p {
            margin: 0;
            font-weight: 500; }
            .single-reservation > div > div > div > aside > div p:first-child {
              font-weight: 300;
              color: #333333;
              font-size: 0.8em;
              padding-bottom: 8px; }
      .single-reservation > div > div > div > div {
        flex: 1 1 75%; }
        .single-reservation > div > div > div > div > div.single-reservation-links {
          text-align: right;
          padding: 24px; }
          .single-reservation > div > div > div > div > div.single-reservation-links a {
            text-decoration: none;
            margin: 10px 0;
            display: inline-block; }
        .single-reservation > div > div > div > div > div > div.list-item-row {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #d9d9d9;
          padding: 24px;
          font-weight: 500;
          font-size: 16px; }
          .single-reservation > div > div > div > div > div > div.list-item-row.flight {
            flex-direction: column; }
          .single-reservation > div > div > div > div > div > div.list-item-row > div:first-child {
            font-weight: 300;
            color: #333333; }
          .single-reservation > div > div > div > div > div > div.list-item-row > div.coverage {
            text-align: right; }

@media only screen and (max-width: 600px) {
  .single-reservation {
    margin: 0; }
    .single-reservation > header {
      padding: 0; }
      .single-reservation > header > h2 {
        padding: 24px; }
    .single-reservation > div.card {
      box-shadow: none;
      border: none; }
    .single-reservation > div > div > div {
      display: flex;
      flex-direction: column; }
      .single-reservation > div > div > div > aside {
        align-self: normal;
        margin-right: 0; }
      .single-reservation > div > div > div > div {
        flex: 1 1 auto; }
        .single-reservation > div > div > div > div > div.list-item-row {
          flex-flow: column; }
          .single-reservation > div > div > div > div > div.list-item-row > div {
            padding: 4px 0; } }

.confirm-booking-date-time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px; }

.date-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

@media only screen and (max-width: 840px) {
  .estimated-total {
    margin-bottom: 1.25rem; } }

.upcoming-booking-container {
  background-color: #f2f2f2;
  padding: 50px;
  position: relative;
  height: 40%;
  width: 100%;
  overflow-y: unset; }

.upcoming-booking-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row; }

.upcoming-booking-subheader {
  display: flex;
  align-items: flex-end;
  margin-bottom: 66px; }

.upcoming-booking-border {
  height: 0.063rem;
  width: 100%;
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
  background-color: #d9d9d9; }

.upcoming-booking-details-img {
  position: absolute;
  top: 39.75rem;
  right: 3.125rem;
  height: 18.327rem;
  width: auto; }

.upcoming-booking-details-footer {
  display: flex;
  justify-content: space-between; }

.upcoming-booking-details-first-column,
.upcoming-booking-details-second-column,
.upcoming-booking-details-third-column {
  display: flex;
  flex-direction: column; }

@media only screen and (max-width: 840px) {
  .upcoming-booking-container {
    height: 75%;
    padding: 1rem;
    width: 100%; }
  .upcoming-booking-details {
    flex-direction: column; }
  .upcoming-booking-details-img {
    top: 53.5rem;
    height: 10.371rem; }
  .upcoming-booking-subheader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1.875rem; }
  .upcoming-booking-border {
    margin: 1.25rem 0; } }

@media only screen and (max-width: 600px) {
  .upcoming-booking-container {
    height: 75%;
    padding: 1rem;
    width: 100%; }
  .upcoming-booking-details {
    flex-direction: column; }
  .upcoming-booking-details-img {
    position: absolute;
    left: 32px;
    top: 54rem;
    height: 10.371rem; }
  .upcoming-booking-subheader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1.875rem; }
  .upcoming-booking-border {
    margin: 1.25rem 0; } }

.section-subheader {
  width: 100%;
  padding-top: 25px; }

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem; }
  .button-container button {
    margin: 0.5rem !important; }

.modify-coverage-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1328px; }

@media only screen and (max-width: 480px) {
  .button-container {
    display: inherit;
    justify-content: inherit;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 1rem; } }

.sc-date-picker {
  display: flex;
  flex: 1 1;
  padding-top: 24px;
  overflow-y: scroll;
  justify-content: center;
  margin: 0 auto; }
  .sc-date-picker > div {
    width: 500px; }
    .sc-date-picker > div > .DayPicker {
      background: none;
      box-shadow: none; }
      .sc-date-picker > div > .DayPicker .DayPicker_weekHeader {
        padding: 0; }
        .sc-date-picker > div > .DayPicker .DayPicker_weekHeader > .DayPicker_weekHeader_ul {
          font-size: 1rem;
          font-family: AudiType, sans-serif;
          color: gray; }
        .sc-date-picker > div > .DayPicker .DayPicker_weekHeader .DayPicker_weekHeader_li > small {
          font-size: 0.75rem !important; }
      .sc-date-picker > div > .DayPicker .CalendarMonthGrid {
        background: none;
        padding-bottom: 48px; }
        .sc-date-picker > div > .DayPicker .CalendarMonthGrid .CalendarMonth {
          background: none; }
      .sc-date-picker > div > .DayPicker .DayPicker_transitionContainer__verticalScrollable {
        overflow: visible;
        height: auto; }
      .sc-date-picker > div > .DayPicker .DayPicker_weekHeader__verticalScrollable {
        background: none;
        border-bottom: none; }
      .sc-date-picker > div > .DayPicker .DayPickerNavigation_container {
        display: none; }
      .sc-date-picker > div > .DayPicker .CalendarDay {
        background: none; }
        .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__blocked_out_of_range {
          background: white !important;
          color: #cacccd !important; }
        .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__default {
          border: none;
          color: #333333;
          height: 42px !important;
          font-family: AudiType, sans-serif;
          font-size: 0.75rem !important;
          font-weight: 100;
          width: 51px !important; }
          .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__highlighted_calendar {
            background: inherit;
            color: #333333; }
          .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected {
            background: black !important;
            color: #e5e5e5 !important; }
            .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected.CalendarDay__blocked_out_of_range {
              background: white !important;
              color: #cacccd !important; }
          .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected_span {
            background: #f2f2f2;
            color: #333333; }
            .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected_span.CalendarDay__blocked_out_of_range {
              background: #f2f2f2 !important; }
              .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__selected_span.CalendarDay__blocked_out_of_range.CalendarDay__selected_start {
                background: black !important; }
          .sc-date-picker > div > .DayPicker .CalendarDay.CalendarDay__default.CalendarDay__hovered_span {
            background: #f2f2f2;
            color: black; }
      .sc-date-picker > div > .DayPicker .CalendarMonth_caption {
        font-family: AudiType, sans-serif;
        font-size: 0.75rem; }
        .sc-date-picker > div > .DayPicker .CalendarMonth_caption > strong {
          color: gray;
          font-weight: 100 !important; }
      .sc-date-picker > div > .DayPicker .CalendarMonthGrid__vertical_scrollable {
        overflow-y: unset; }

.DayPickerNavigation_button {
  background: none; }

@media only screen and (max-width: 480px) {
  .sc-date-picker {
    -webkit-overflow-scrolling: touch; }
    .sc-date-picker > div {
      width: 100vw; }
      .sc-date-picker > div > .DayPicker .CalendarMonthGrid {
        width: 100vw !important; }
      .sc-date-picker > div > .DayPicker .DayPicker_weekHeader .DayPicker_weekHeader_li {
        width: 40px !important; }
      .sc-date-picker > div > .DayPicker .CalendarDay {
        width: 40px !important;
        height: 46px !important; } }

.reservation-edit-dates {
  background: white;
  display: flex;
  flex: 1 1;
  flex-flow: column; }
  .reservation-edit-dates .modify-dates-subheader {
    display: flex;
    justify-content: center;
    text-align: center; }
    .reservation-edit-dates .modify-dates-subheader > div {
      padding: 15px; }
  .reservation-edit-dates > div {
    display: flex;
    justify-content: center;
    text-align: center; }
  .reservation-edit-dates .header-container {
    justify-content: space-around; }
  .reservation-edit-dates > header {
    background-color: white; }
  .reservation-edit-dates > section {
    display: flex;
    flex: 1 1; }

@media only screen and (max-width: 480px) {
  .reservation-edit-dates table {
    margin: 0 auto; }
  .reservation-edit-dates > section .date-picker-container {
    margin-top: 0; } }

.sc-time-picker {
  display: flex;
  flex: 1 1;
  padding: 24px;
  overflow-y: scroll;
  justify-content: center;
  margin: 0 auto; }
  .sc-time-picker > div {
    display: flex;
    flex: 1 1;
    min-height: -webkit-min-content;
    min-height: -moz-min-content;
    min-height: min-content;
    justify-content: center; }
    .sc-time-picker > div > div {
      border-right: 1px solid #bdbdbd; }
    .sc-time-picker > div ul {
      list-style-type: none;
      margin: 0 30px;
      padding: 0; }
      .sc-time-picker > div ul.pickup {
        margin-right: 0; }
      .sc-time-picker > div ul.return {
        margin-left: 0; }
      .sc-time-picker > div ul li {
        color: #666666;
        padding: 10px;
        margin: 0;
        text-align: center;
        font-family: AudiType, sans-serif;
        font-size: 1rem;
        font-weight: 100;
        cursor: pointer; }
        .sc-time-picker > div ul li.selected, .sc-time-picker > div ul li:focus {
          background-color: black;
          color: white;
          outline: 0; }
        .sc-time-picker > div ul li.disabled {
          color: #c3c3c3;
          text-decoration: line-through;
          cursor: default; }

@media only screen and (max-width: 480px) {
  .sc-time-picker {
    -webkit-overflow-scrolling: touch; } }

.reservation-edit-times {
  display: flex;
  flex-flow: column;
  flex: 1 1;
  background: white; }
  .reservation-edit-times .modify-times-subheader {
    display: flex;
    justify-content: center;
    text-align: center; }
    .reservation-edit-times .modify-times-subheader > div {
      padding: 15px; }
  .reservation-edit-times .subsection-active {
    border-bottom: 5px solid black; }
  .reservation-edit-times > div {
    display: flex;
    justify-content: center;
    text-align: center; }
  .reservation-edit-times .header-container {
    justify-content: space-around; }
  .reservation-edit-times > header {
    background-color: white; }
  .reservation-edit-times > section {
    display: flex;
    flex: 1 1;
    height: 0; }

@media only screen and (max-width: 480px) {
  .reservation-edit-times table {
    margin: 0 auto; }
  .reservation-edit-times > section {
    -webkit-overflow-scrolling: touch; } }

.modified-price {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .modified-price .modified-price-number-display {
    color: #333333; }

.previous-price {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .previous-price .previous-price-number-display {
    font-weight: 100;
    color: #666666; }

.modify-reservation-modal-content {
  width: 80%; }

.modify-reservation-buttons button {
  max-width: 6.75rem; }

.reservation-detail {
  display: flex;
  flex-flow: column;
  flex: 1 1; }

.sc-vehicle-select.modify {
  flex: 1 1;
  display: flex;
  flex-flow: column;
  background-color: white; }
  .sc-vehicle-select.modify .currently-selected-button {
    background-color: black; }
  .sc-vehicle-select.modify > div .total-and-earn-points {
    margin-bottom: 40px; }
  .sc-vehicle-select.modify > div > div {
    flex-flow: column; }
    .sc-vehicle-select.modify > div > div.section-container {
      display: flex;
      padding-bottom: 24px;
      align-items: center; }
      .sc-vehicle-select.modify > div > div.section-container > .sc-vehicle > section > .pricing.earn {
        margin-bottom: 20px; }
      .sc-vehicle-select.modify > div > div.section-container > .section-subheader {
        border-bottom: 1px solid #b3b3b3;
        padding-bottom: 10px;
        margin-bottom: 20px;
        align-items: center; }

.modify-vehicle-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1328px; }

.header-container {
  display: flex;
  justify-content: space-between;
  margin: 24px 0 40px; }

.vehicle-image.disabled {
  opacity: 0.5; }

@media only screen and (max-width: 840px) {
  .sc-vehicle-select > div > div.section-container > .section-subheader {
    width: 100%;
    display: block;
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 20px; } }

@media only screen and (max-width: 480px) {
  .sc-vehicle-select > div {
    -webkit-overflow-scrolling: touch; }
    .sc-vehicle-select > div > div.section-container {
      padding: 24px; }
  .vehicle-image {
    max-width: 100%; }
  .modify > div > .section-container > .sc-vehicle > .pricing-and-availability-info > .pricing-container-top > div.cost-and-points {
    justify-content: flex-end !important; }
    .modify > div > .section-container > .sc-vehicle > .pricing-and-availability-info > .pricing-container-top > div.cost-and-points div.total-and-earn-points {
      margin-bottom: 0 !important; }
  h4.vehicle-unavailable-notice {
    margin-bottom: 0; }
  .header-container {
    flex-direction: column;
    padding: 0 24px; }
    .header-container > header {
      display: flex;
      justify-content: center; }
    .header-container > button {
      margin-top: 24px; } }

.sc-sidenav > .login {
  padding: 1.875rem 1rem;
  border-bottom: 1px solid #b3b3b3; }
  .sc-sidenav > .login > span {
    padding-right: 0.5rem; }

.sc-sidenav.open > nav {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
  .sc-sidenav.open > nav > .link-container {
    display: block;
    border-top: 1px solid #b3b3b3; }
    .sc-sidenav.open > nav > .link-container > section > a {
      text-decoration: none; }
      .sc-sidenav.open > nav > .link-container > section > a > div {
        flex: 1 1;
        margin: 0;
        color: black;
        border-bottom: 1px solid #b3b3b3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-decoration: none;
        padding: 1.25rem; }
        .sc-sidenav.open > nav > .link-container > section > a > div > button {
          align-self: center; }
        .sc-sidenav.open > nav > .link-container > section > a > div > div {
          flex: 1 1;
          margin: 0;
          color: black;
          display: flex;
          flex-direction: column; }
          .sc-sidenav.open > nav > .link-container > section > a > div > div > p {
            font-size: 1.25rem;
            font-weight: 700;
            margin: 0;
            line-height: 1;
            padding: unset; }
            .sc-sidenav.open > nav > .link-container > section > a > div > div > p > span {
              margin-left: 8px; }
            .sc-sidenav.open > nav > .link-container > section > a > div > div > p.sc-rewards-item {
              font-weight: 400;
              font-size: 0.875rem;
              padding-top: 0.75rem; }
              .sc-sidenav.open > nav > .link-container > section > a > div > div > p.sc-rewards-item > span {
                font-weight: 700;
                font-size: 1.25rem; }
        .sc-sidenav.open > nav > .link-container > section > a > div > .sc-loading {
          display: block;
          padding: 8px 16px 3px; }

.sc-sidenav.open > button {
  display: block; }

.sc-sidenav > button {
  display: none;
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: none;
  border: 0;
  padding: 0;
  margin: 0; }

.sc-sidenav > nav {
  position: fixed;
  display: flex;
  flex-flow: column;
  top: 0;
  left: 0;
  width: 442px;
  height: 100vh;
  z-index: 10000;
  background: white;
  -webkit-transform: translate(-500px, 0);
          transform: translate(-500px, 0);
  -webkit-transition: -webkit-transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: -webkit-transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, -webkit-transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .sc-sidenav > nav[aria-hidden="true"] {
    visibility: hidden; }
  .sc-sidenav > nav > header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.25rem; }
    .sc-sidenav > nav > header > a {
      align-self: center;
      height: 1.5rem; }
      .sc-sidenav > nav > header > a > img {
        height: 100%; }
  .sc-sidenav > nav > section {
    display: flex;
    flex-flow: row; }
    .sc-sidenav > nav > section:first-of-type {
      align-items: center;
      padding-bottom: 16px; }
      .sc-sidenav > nav > section:first-of-type > a {
        display: flex;
        flex: 1 1;
        flex-flow: column;
        align-items: center;
        padding-bottom: 16px;
        text-decoration: none;
        color: black !important; }
        .sc-sidenav > nav > section:first-of-type > a:not(:first-child) {
          border-left: 1px solid #b3b3b3; }
        .sc-sidenav > nav > section:first-of-type > a > i {
          font-size: 3.5em; }
        .sc-sidenav > nav > section:first-of-type > a > footer {
          font-size: 1.5em;
          font-weight: 200; }
    .sc-sidenav > nav > section > ul {
      flex: 1 1;
      margin: 0;
      padding: 0 1.25rem;
      list-style: none; }
      .sc-sidenav > nav > section > ul.help {
        margin-top: 2rem;
        border-top: 1px solid #b3b3b3; }
      .sc-sidenav > nav > section > ul > li {
        padding-top: 2rem;
        font-size: 1.25rem; }
        .sc-sidenav > nav > section > ul > li > a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: black; }
          .sc-sidenav > nav > section > ul > li > a > i {
            font-size: 1.25rem; }
          .sc-sidenav > nav > section > ul > li > a > span {
            border: 0;
            font-weight: 200;
            background: none; }

@media only screen and (max-width: 480px) {
  .sc-sidenav.open > nav {
    width: 100vw;
    overflow: scroll;
    height: 100vh;
    position: absolute; }
    .sc-sidenav.open > nav > .link-container > section > .sc-loading {
      padding-bottom: 2px; } }

@media only screen and (max-width: 840px) and (orientation: landscape) {
  .sc-sidenav.open > nav {
    overflow: scroll; }
    .sc-sidenav.open > nav > section:first-of-type {
      margin: 64px 0 24px; }
    .sc-sidenav.open > nav > .link-container {
      margin-top: 24px; } }

.header {
  display: flex;
  margin: 0 1rem;
  flex-direction: row; }

.audi-text-outer {
  justify-content: flex-start;
  height: 2rem; }

.close-icon {
  justify-content: flex-start;
  margin-top: 1rem; }

.dropdown-links {
  display: flex;
  margin: 0 1rem 0 2rem;
  align-content: center;
  flex-flow: column;
  height: 100%; }

.audi-owners {
  text-decoration: none;
  justify-content: center; }

.login {
  justify-content: flex-start;
  text-decoration: none;
  margin-top: 2rem;
  flex-grow: 1; }

.username-or-email-text {
  word-wrap: break-word;
  max-width: 80%;
  width: 80%; }

.username-email-dropdown {
  display: flex;
  flex-flow: row;
  justify-content: space-between; }

.chevron {
  font-size: 4rem;
  justify-content: flex-end; }

.icon-sizing {
  width: 2em !important;
  height: 1.5em !important; }

.chevron-up {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.chevron-down {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.loyalty-points-container {
  background-color: #f2f2f2;
  height: 4rem;
  margin-top: -1rem;
  display: flex;
  margin-left: -1rem; }

.spacing {
  margin-top: 1.5rem;
  text-decoration: none; }

.loyalty-points-text {
  margin-top: 1rem;
  width: 78rem;
  margin-left: 1rem; }

.loyalty-points-number {
  margin-top: 1rem;
  margin-right: 1rem; }

.button-that-looks-like-a-link {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-right: auto; }

.mobile-booking {
  align-self: stretch;
  margin-bottom: 30px;
  margin-top: auto; }

@media only screen and (max-width: 480px) {
  .signup-form {
    margin: 0 !important; } }

body {
  margin: 0;
  padding: 0;
  font-family: sba, sans-serif; }

.fof-container {
  background-image: url(https://s3.amazonaws.com/production-silvercar-static-assets/assets/nginx-assets/404.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed; }

.fof-message {
  color: white;
  text-align: center; }

.fof-message a {
  color: white;
  background: #45abc7;
  padding: 10px;
  border-radius: 15px;
  text-decoration: none; }

.fof-message h1 {
  font-weight: 500; }

@media (max-width: 768px) {
  .fof-container {
    background-image: url(https://s3.amazonaws.com/production-silvercar-static-assets/assets/nginx-assets/404-mobile.png); }
  .fof-message > h1 {
    font-size: 70px; } }

@media (min-width: 769px) {
  .fof-message {
    width: 50%;
    margin-left: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .fof-message h1 {
    margin: 0;
    margin: initial;
    font-size: 200px; }
  .fof-message h5 {
    font-size: 25px;
    margin: 0;
    font-weight: 500; } }

.sc-form > section > section > form > .button-group {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  text-align: center; }

.curbside {
  padding: 0 24px; }

@media only screen and (max-width: 480px) {
  .sc-form > section > section > form > .button-group {
    flex-direction: column; } }

@media only screen and (max-width: 480px) {
  .thank-you-form {
    margin: 6.25rem 0 !important; }
    .thank-you-form-button {
      width: auto !important; } }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Book.ttf") format("truetype");
  font-weight: 100 400;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BookItalic.ttf") format("truetype");
  font-weight: 100 400;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Black.ttf") format("truetype");
  font-weight: 700 900;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BlackItalic.ttf") format("truetype");
  font-weight: 700 900;
  font-style: italic; }

@font-face {
  font-family: silvercar;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("silvercar"), url("/assets/fonts/sc-icons.eot"), url("/assets/fonts/sc-icons.eot?") format("embedded-opentype"), url("/assets/fonts/sc-icons.woff") format("woff"), url("/assets/fonts/sc-icons.ttf") format("truetype"), url("/assets/fonts/sc-icons.svg") format("svg"); }

@font-face {
  font-family: QuatroBold;
  src: url("/assets/fonts/Quatro-Bold.ttf");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: AudiType-ExtendedNormal;
  src: url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff") format("woff"), url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: AudiType-Variable;
  src: url("/assets/fonts/AudiTypeVF_4.04.ttf") format("truetype"), url("/assets/fonts/AudiTypeVF_4.04.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, and/or their suppliers. You may not
attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com
*/
@font-face {
  font-family: BentonSansComp;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("BentonSansComp"), url("/assets/fonts/BentonSansCompMedium.eot"), url("/assets/fonts/BentonSansCompMedium.eot?") format("embedded-opentype"), url("/assets/fonts/BentonSansCompMedium.woff") format("woff"), url("/assets/fonts/BentonSansCompMedium.ttf") format("truetype"), url("/assets/fonts/BentonSansCompMedium.svg#web") format("svg"); }

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font: 400 normal normal 14px/1 silvercar, sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-size: inherit;
  text-rendering: auto;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-building::before {
  content: "\e911"; }

.icon-airplane::before {
  content: "\e600"; }

.icon-arrow::before {
  content: "\e601"; }

.icon-calendar_::before {
  content: "\e602"; }

.icon-calendar_reservation::before {
  content: "\e603"; }

.icon-calendar_x::before {
  content: "\e604"; }

.icon-calendar::before {
  content: "\e605"; }

.icon-check_mark_in_circle::before {
  content: "\e606"; }

.icon-clock::before {
  content: "\e607"; }

.icon-facebook_f::before {
  content: "\e608"; }

.icon-facebook::before {
  content: "\e609"; }

.icon-google_g::before {
  content: "\e60a"; }

.icon-instagram::before {
  content: "\e60b"; }

.icon-marketing_site_arrows::before {
  content: "\e60c"; }

.icon-marketing_site_bell::before {
  content: "\e60d"; }

.icon-marketing_site_check-mark::before,
.icon-check-mark::before {
  content: "\e60e"; }

.icon-marketing_site_phone::before {
  content: "\e60f"; }

.icon-marketing_site_wallet::before {
  content: "\e610"; }

.icon-narrow_arrow::before {
  content: "\e611"; }

.icon-navicon::before {
  content: "\e612"; }

.icon-navigation_account::before {
  content: "\e613"; }

.icon-navigation_contact_us::before {
  content: "\e614"; }

.icon-navigation_home::before {
  content: "\e615"; }

.icon-navigation_inbox::before {
  content: "\e616"; }

.icon-navigation_locations::before {
  content: "\e617"; }

.icon-navigation_refer_a_friend::before {
  content: "\e618"; }

.icon-navigation_reservations::before {
  content: "\e619"; }

.icon-navigation_reserve_a_car::before {
  content: "\e61a"; }

.icon-twitter::before {
  content: "\e61b"; }

.icon-arrow_left::before {
  content: "\e900"; }

.icon-arrow_right::before {
  content: "\e901"; }

.icon-arrow_up::before {
  content: "\e902"; }

.icon-navigation_blog::before {
  content: "\e903"; }

.icon-navigation_booked_third_party::before {
  content: "\e904"; }

.icon-navigation_how_it_works::before {
  content: "\e905"; }

.icon-navigation_log_in::before {
  content: "\e906"; }

.icon-navigation_log_out {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }
  .icon-navigation_log_out::before {
    content: "\e906"; }

.icon-arrow-left::before,
.icon-narrow_arrow_left::before {
  content: "\e907"; }

.icon-arrow-right::before,
.icon-narrow_arrow_right::before {
  content: "\e908"; }

.icon-narrow_arrow-up::before {
  content: "\e909"; }

.icon-narrow_arrow-down::before {
  content: "\e912"; }

.icon-close::before,
.icon-narrow_close::before {
  content: "\e90a"; }

.icon-navigation_policies::before {
  content: "\e90b"; }

.icon-navigation_sign_up::before {
  content: "\e90c"; }

.icon-tips_and_tricks {
  content: "\e90d"; }

.icon-EmergencyIcon::before {
  content: "\e90e"; }

.icon-QuestionIcon::before {
  content: "\e90f"; }

.icon-ReservationsIcon::before {
  content: "\e910"; }

a {
  color: black; }

button.primary {
  color: white !important;
  background: black !important; }
  button.primary a {
    color: white !important; }

button.secondary {
  color: rgba(0, 0, 0, 0.87) !important;
  background: white !important;
  border: 1px solid black !important; }

button.tertiary {
  color: rgba(0, 0, 0, 0.87) !important;
  background: rgba(0, 0, 0, 0.05) !important;
  border: none !important; }

button.sc {
  align-self: center;
  display: inline-block;
  padding: 0 !important;
  border-radius: 2em !important; }
  button.sc > div {
    padding: 0 24px; }
  button.sc > span {
    padding: 0 40px !important; }

button a {
  text-transform: capitalize; }

@media only screen and (max-width: 480px) {
  button.sc {
    width: 100% !important; } }

h1 {
  font-family: QuatroBold, sans-serif; }

a.skip-link {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px; }

a.sc-link {
  text-decoration: none;
  color: black; }
  a.sc-link:hover {
    text-decoration: underline; }
  a.sc-link.danger {
    color: #d62408; }

*:focus {
  outline: 2px dotted #707070;
  outline-offset: 4px; }

.grid-container {
  max-width: 1504px; }

.flex-row {
  display: flex;
  flex-direction: row;
  flex: 0 0; }

.justify-space-between {
  justify-content: space-between; }

.justify-space-around {
  justify-content: space-around; }

.drastically-different-label-style {
  color: black;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 400; }
  .drastically-different-label-style > span {
    font-size: 16px;
    margin-left: 4px; }

.section-container {
  max-width: 1328px;
  margin: 0 auto; }

.btn-link {
  border-radius: 0;
  background: none;
  border: none;
  font-family: sba, sans-serif;
  font-size: 14px;
  text-decoration: none; }

.card {
  border: 1px solid #e2e2e2;
  box-shadow: 1px 1px 1px 0 #e2e2e2;
  margin-bottom: 10px; }
  .card-white {
    background-color: white; }

.no-margin {
  margin-bottom: unset !important; }

.clickable {
  cursor: pointer; }

.disabled {
  pointer-events: none;
  opacity: 0.3;
  cursor: not-allowed; }

.menu-hover:hover {
  background-color: #f2f2f2; }

.App {
  display: flex;
  flex-flow: column;
  flex: auto;
  height: 100%;
  min-height: 1080px; }
  .App .hide-lg {
    display: none !important; }
  .App .hide-mob {
    display: none !important; }
  .App .sc-sidenav {
    flex: none; }
  .App > div {
    display: flex;
    flex-flow: column;
    flex: 1 1; }
    .App > div > main {
      flex: 1 1;
      display: flex;
      flex-flow: column;
      overflow-y: scroll; }

@media only screen and (max-width: 480px) {
  .App {
    min-height: unset; }
    .App[aria-disabled="true"] {
      overflow: hidden; }
    .App > main {
      -webkit-overflow-scrolling: touch; }
      .App > main > main {
        -webkit-overflow-scrolling: touch; }
    .App .hide-xs {
      display: none !important; }
    .App .hide-lg {
      display: block !important; }
    .App .hide-mob {
      display: flex !important; } }

.label-animation label {
  -webkit-animation-name: none !important;
          animation-name: none !important; }

.fix-red-label label {
  color: black !important; }

label:is(:where(:focus-within, :focus, :hover, :active)) {
  color: black !important; }

.global-footer-container {
  background-image: url(/static/media/Audi_Rings_Light_wh-RGB.45812b6a.svg);
  background-position: -2.9rem 24.4rem;
  padding-left: 8.5rem;
  background-size: 57rem 19.8rem;
  background-repeat: no-repeat;
  background-color: #000;
  height: 30.81rem;
  padding-top: 5.12rem;
  display: flex;
  flex-flow: row; }
  .global-footer-container .footer-branding {
    display: block;
    flex-grow: 1; }

.footer-navigation {
  display: flex;
  flex-grow: 2;
  grid-column-gap: 6.5rem;
  -webkit-column-gap: 6.5rem;
     -moz-column-gap: 6.5rem;
          column-gap: 6.5rem; }

.menu-container {
  display: flex;
  flex-flow: column;
  grid-row-gap: 1rem;
  row-gap: 1rem; }

.global-footer-link {
  text-decoration: none;
  margin-bottom: 0.25rem; }

.footer-socials-container {
  margin-top: 4.25rem; }

.footer-social {
  margin-right: 1.8rem; }

.global-footer-container-mobile {
  background-image: url(/static/media/Audi_Rings_Light_wh-RGB.45812b6a.svg);
  background-position: -2.9rem 34.2783074rem;
  padding-left: 1.3rem;
  background-size: 20.5rem 6.9rem;
  background-repeat: no-repeat;
  background-color: #000;
  height: 34.81rem;
  padding-top: 3.93rem;
  display: flex;
  flex-flow: column; }
  .global-footer-container-mobile .footer-branding {
    margin-bottom: 2.3rem; }
  .global-footer-container-mobile .global-footer-link {
    text-decoration: none; }
  .global-footer-container-mobile .global-footer-link-text {
    margin: 0 0 0.5rem; }
  .global-footer-container-mobile .footer-socials-container {
    margin-top: 2.8rem; }
  .global-footer-container-mobile .footer-social {
    margin-right: 1.8rem; }

