.join-reserve-form > form {
  margin-top: 0; }

.form-link-container {
  text-align: left !important;
  font-size: 14px !important;
  margin-top: 15px !important;
  margin-bottom: 0 !important;
  color: #707070; }
  .form-link-container > a {
    color: #707070 !important; }

@media only screen and (max-width: 480px) {
  .policy-section {
    width: auto; } }
