iframe form label {
  left: 0 !important; }

.hosted-field-container {
  margin: 0.8em 0 1.3em; }
  .hosted-field-container .bt-label {
    line-height: 20px;
    color: #333333;
    font-size: 12px;
    font-family: AudiType-Variable, sans-serif;
    font-stretch: 105%; }

.hosted-field {
  height: 32px;
  display: block;
  background-color: transparent;
  color: black;
  border: none;
  border-bottom: 1px solid black;
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative; }

/* --------------------
BT HOSTED FIELDS SPECIFIC
-------------------- */
.braintree-hosted-fields-focused {
  border-bottom: 2px solid black;
  transition: all 200ms ease; }

.braintree-hosted-fields-invalid {
  border-bottom: 2px solid #d35656;
  transition: all 200ms ease; }

#credit-card-form {
  margin: 1.5rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  #credit-card-form #details-section,
  #credit-card-form #address-section {
    flex-direction: column; }
  #credit-card-form #details-section {
    width: 45%; }
  #credit-card-form #address-section {
    width: 45%; }
  #credit-card-form .half-section {
    display: flex;
    margin-top: 1rem;
    flex-direction: row;
    justify-content: space-between; }
  #credit-card-form .half {
    width: 45%; }
    #credit-card-form .half#province {
      width: 52.5%; }

#credit-card-button-section {
  display: flex;
  margin: 4rem 0 0;
  width: 100%;
  gap: 0.25rem;
  justify-content: flex-end; }

@media only screen and (max-width: 960px) {
  #credit-card-form {
    flex-direction: column; }
    #credit-card-form #details-section,
    #credit-card-form #address-section {
      flex-direction: column;
      width: 100%; }
    #credit-card-form #address-section {
      margin: 4.5rem 0 0; }
    #credit-card-form .half-section {
      flex-direction: column; }
    #credit-card-form .half {
      width: auto; }
      #credit-card-form .half#province {
        width: auto; }
  #credit-card-button-section {
    justify-content: center;
    flex-direction: column; }
  .credit-card-button {
    width: 100% !important; } }
