body {
  margin: 0;
  padding: 0;
  font-family: sba, sans-serif; }

.fof-container {
  background-image: url(https://s3.amazonaws.com/production-silvercar-static-assets/assets/nginx-assets/404.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed; }

.fof-message {
  color: white;
  text-align: center; }

.fof-message a {
  color: white;
  background: #45abc7;
  padding: 10px;
  border-radius: 15px;
  text-decoration: none; }

.fof-message h1 {
  font-weight: 500; }

@media (max-width: 768px) {
  .fof-container {
    background-image: url(https://s3.amazonaws.com/production-silvercar-static-assets/assets/nginx-assets/404-mobile.png); }
  .fof-message > h1 {
    font-size: 70px; } }

@media (min-width: 769px) {
  .fof-message {
    width: 50%;
    margin-left: 50%;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .fof-message h1 {
    margin: initial;
    font-size: 200px; }
  .fof-message h5 {
    font-size: 25px;
    margin: 0;
    font-weight: 500; } }
