.global-footer-container {
  background-image: url("../../assets/images/background/Audi_Rings_Light_wh-RGB.svg");
  background-position: -2.9rem 24.4rem;
  padding-left: 8.5rem;
  background-size: 57rem 19.8rem;
  background-repeat: no-repeat;
  background-color: #000;
  height: 30.81rem;
  padding-top: 5.12rem;
  display: flex;
  flex-flow: row; }
  .global-footer-container .footer-branding {
    display: block;
    flex-grow: 1; }

.footer-navigation {
  display: flex;
  flex-grow: 2;
  column-gap: 6.5rem; }

.menu-container {
  display: flex;
  flex-flow: column;
  row-gap: 1rem; }

.global-footer-link {
  text-decoration: none;
  margin-bottom: 0.25rem; }

.footer-socials-container {
  margin-top: 4.25rem; }

.footer-social {
  margin-right: 1.8rem; }

.global-footer-container-mobile {
  background-image: url("../../assets/images/background/Audi_Rings_Light_wh-RGB.svg");
  background-position: -2.9rem 34.2783074rem;
  padding-left: 1.3rem;
  background-size: 20.5rem 6.9rem;
  background-repeat: no-repeat;
  background-color: #000;
  height: 34.81rem;
  padding-top: 3.93rem;
  display: flex;
  flex-flow: column; }
  .global-footer-container-mobile .footer-branding {
    margin-bottom: 2.3rem; }
  .global-footer-container-mobile .global-footer-link {
    text-decoration: none; }
  .global-footer-container-mobile .global-footer-link-text {
    margin: 0 0 0.5rem; }
  .global-footer-container-mobile .footer-socials-container {
    margin-top: 2.8rem; }
  .global-footer-container-mobile .footer-social {
    margin-right: 1.8rem; }
