@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Open+Sans:300,400,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons&display=swap");
@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Book.ttf") format("truetype");
  font-weight: 100 400;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BookItalic.ttf") format("truetype");
  font-weight: 100 400;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-Black.ttf") format("truetype");
  font-weight: 700 900;
  font-style: normal; }

@font-face {
  font-family: sba;
  font-display: fallback;
  src: url("/assets/fonts/SilvercarCircular-BlackItalic.ttf") format("truetype");
  font-weight: 700 900;
  font-style: italic; }

@font-face {
  font-family: silvercar;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("silvercar"), url("/assets/fonts/sc-icons.eot"), url("/assets/fonts/sc-icons.eot?") format("embedded-opentype"), url("/assets/fonts/sc-icons.woff") format("woff"), url("/assets/fonts/sc-icons.ttf") format("truetype"), url("/assets/fonts/sc-icons.svg") format("svg"); }

@font-face {
  font-family: QuatroBold;
  src: url("/assets/fonts/Quatro-Bold.ttf");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: AudiType-ExtendedNormal;
  src: url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff") format("woff"), url("/assets/fonts/AudiType-ExtendedNormal_4.03.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: AudiType-Variable;
  src: url("/assets/fonts/AudiTypeVF_4.04.ttf") format("truetype"), url("/assets/fonts/AudiTypeVF_4.04.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, and/or their suppliers. You may not
attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com
*/
@font-face {
  font-family: BentonSansComp;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("BentonSansComp"), url("/assets/fonts/BentonSansCompMedium.eot"), url("/assets/fonts/BentonSansCompMedium.eot?") format("embedded-opentype"), url("/assets/fonts/BentonSansCompMedium.woff") format("woff"), url("/assets/fonts/BentonSansCompMedium.ttf") format("truetype"), url("/assets/fonts/BentonSansCompMedium.svg#web") format("svg"); }

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  font: 400 normal normal 14px/1 silvercar, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  font-size: inherit;
  text-rendering: auto;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-building::before {
  content: "\e911"; }

.icon-airplane::before {
  content: "\e600"; }

.icon-arrow::before {
  content: "\e601"; }

.icon-calendar_::before {
  content: "\e602"; }

.icon-calendar_reservation::before {
  content: "\e603"; }

.icon-calendar_x::before {
  content: "\e604"; }

.icon-calendar::before {
  content: "\e605"; }

.icon-check_mark_in_circle::before {
  content: "\e606"; }

.icon-clock::before {
  content: "\e607"; }

.icon-facebook_f::before {
  content: "\e608"; }

.icon-facebook::before {
  content: "\e609"; }

.icon-google_g::before {
  content: "\e60a"; }

.icon-instagram::before {
  content: "\e60b"; }

.icon-marketing_site_arrows::before {
  content: "\e60c"; }

.icon-marketing_site_bell::before {
  content: "\e60d"; }

.icon-marketing_site_check-mark::before,
.icon-check-mark::before {
  content: "\e60e"; }

.icon-marketing_site_phone::before {
  content: "\e60f"; }

.icon-marketing_site_wallet::before {
  content: "\e610"; }

.icon-narrow_arrow::before {
  content: "\e611"; }

.icon-navicon::before {
  content: "\e612"; }

.icon-navigation_account::before {
  content: "\e613"; }

.icon-navigation_contact_us::before {
  content: "\e614"; }

.icon-navigation_home::before {
  content: "\e615"; }

.icon-navigation_inbox::before {
  content: "\e616"; }

.icon-navigation_locations::before {
  content: "\e617"; }

.icon-navigation_refer_a_friend::before {
  content: "\e618"; }

.icon-navigation_reservations::before {
  content: "\e619"; }

.icon-navigation_reserve_a_car::before {
  content: "\e61a"; }

.icon-twitter::before {
  content: "\e61b"; }

.icon-arrow_left::before {
  content: "\e900"; }

.icon-arrow_right::before {
  content: "\e901"; }

.icon-arrow_up::before {
  content: "\e902"; }

.icon-navigation_blog::before {
  content: "\e903"; }

.icon-navigation_booked_third_party::before {
  content: "\e904"; }

.icon-navigation_how_it_works::before {
  content: "\e905"; }

.icon-navigation_log_in::before {
  content: "\e906"; }

.icon-navigation_log_out {
  transform: rotate(180deg); }
  .icon-navigation_log_out::before {
    content: "\e906"; }

.icon-arrow-left::before,
.icon-narrow_arrow_left::before {
  content: "\e907"; }

.icon-arrow-right::before,
.icon-narrow_arrow_right::before {
  content: "\e908"; }

.icon-narrow_arrow-up::before {
  content: "\e909"; }

.icon-narrow_arrow-down::before {
  content: "\e912"; }

.icon-close::before,
.icon-narrow_close::before {
  content: "\e90a"; }

.icon-navigation_policies::before {
  content: "\e90b"; }

.icon-navigation_sign_up::before {
  content: "\e90c"; }

.icon-tips_and_tricks {
  content: "\e90d"; }

.icon-EmergencyIcon::before {
  content: "\e90e"; }

.icon-QuestionIcon::before {
  content: "\e90f"; }

.icon-ReservationsIcon::before {
  content: "\e910"; }

a {
  color: black; }

button.primary {
  color: white !important;
  background: black !important; }
  button.primary a {
    color: white !important; }

button.secondary {
  color: rgba(0, 0, 0, 0.87) !important;
  background: white !important;
  border: 1px solid black !important; }

button.tertiary {
  color: rgba(0, 0, 0, 0.87) !important;
  background: rgba(0, 0, 0, 0.05) !important;
  border: none !important; }

button.sc {
  align-self: center;
  display: inline-block;
  padding: 0 !important;
  border-radius: 2em !important; }
  button.sc > div {
    padding: 0 24px; }
  button.sc > span {
    padding: 0 40px !important; }

button a {
  text-transform: capitalize; }

@media only screen and (max-width: 480px) {
  button.sc {
    width: 100% !important; } }

h1 {
  font-family: QuatroBold, sans-serif; }

a.skip-link {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px; }

a.sc-link {
  text-decoration: none;
  color: black; }
  a.sc-link:hover {
    text-decoration: underline; }
  a.sc-link.danger {
    color: #d62408; }

*:focus {
  outline: 2px dotted #707070;
  outline-offset: 4px; }

.grid-container {
  max-width: 1504px; }

.flex-row {
  display: flex;
  flex-direction: row;
  flex: 0 0; }

.justify-space-between {
  justify-content: space-between; }

.justify-space-around {
  justify-content: space-around; }

.drastically-different-label-style {
  color: black;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 400; }
  .drastically-different-label-style > span {
    font-size: 16px;
    margin-left: 4px; }

.section-container {
  max-width: 1328px;
  margin: 0 auto; }

.btn-link {
  border-radius: 0;
  background: none;
  border: none;
  font-family: sba, sans-serif;
  font-size: 14px;
  text-decoration: none; }

.card {
  border: 1px solid #e2e2e2;
  -webkit-box-shadow: 1px 1px 1px 0 #e2e2e2;
  -moz-box-shadow: 1px 1px 1px 0 #e2e2e2;
  box-shadow: 1px 1px 1px 0 #e2e2e2;
  margin-bottom: 10px; }
  .card-white {
    background-color: white; }

.no-margin {
  margin-bottom: unset !important; }

.clickable {
  cursor: pointer; }

.disabled {
  pointer-events: none;
  opacity: 0.3;
  cursor: not-allowed; }

.menu-hover:hover {
  background-color: #f2f2f2; }

.App {
  display: flex;
  flex-flow: column;
  flex: auto;
  height: 100%;
  min-height: 1080px; }
  .App .hide-lg {
    display: none !important; }
  .App .hide-mob {
    display: none !important; }
  .App .sc-sidenav {
    flex: none; }
  .App > div {
    display: flex;
    flex-flow: column;
    flex: 1; }
    .App > div > main {
      flex: 1;
      display: flex;
      flex-flow: column;
      overflow-y: scroll; }

@media only screen and (max-width: 480px) {
  .App {
    min-height: unset; }
    .App[aria-disabled="true"] {
      overflow: hidden; }
    .App > main {
      -webkit-overflow-scrolling: touch; }
      .App > main > main {
        -webkit-overflow-scrolling: touch; }
    .App .hide-xs {
      display: none !important; }
    .App .hide-lg {
      display: block !important; }
    .App .hide-mob {
      display: flex !important; } }

.label-animation label {
  animation-name: none !important; }

.fix-red-label label {
  color: black !important; }

label:is(:where(:focus-within, :focus, :hover, :active)) {
  color: black !important; }
