.sc-vehicle-select {
  flex: 1;
  display: flex;
  flex-flow: column; }
  .sc-vehicle-select > header {
    display: inherit;
    align-items: center;
    justify-content: center;
    padding: 16px;
    flex: 0 0 auto;
    background: white; }
  .sc-vehicle-select .audi-owner-days {
    display: flex; }
    .sc-vehicle-select .audi-owner-days p {
      text-align: right; }
  .sc-vehicle-select .login-container {
    display: flex;
    margin-left: auto;
    justify-content: center;
    margin-bottom: 1.2rem;
    align-items: flex-end; }
    .sc-vehicle-select .login-container > .login-to-pay-textfield {
      margin-top: inherit !important; }
      .sc-vehicle-select .login-container > .login-to-pay-textfield > .login-to-pay-link {
        font-weight: 400; }
  .sc-vehicle-select > div {
    max-width: 1328px;
    width: 95vw; }
    .sc-vehicle-select > div > section {
      flex-flow: column; }
      .sc-vehicle-select > div > section.section-container {
        display: flex;
        padding: 24px 0; }
        .sc-vehicle-select > div > section.section-container > .section-header {
          width: 100%;
          display: inherit;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 2.125rem; }
          .sc-vehicle-select > div > section.section-container > .section-header div.select-model-subsection {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .sc-vehicle-select > div > section.section-container > .section-header div.select-model-subsection > .title-and-included-options {
              display: flex;
              flex-direction: column;
              justify-content: space-between; }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div {
            display: flex;
            flex-direction: row;
            margin-top: 10px; }
            .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > div {
              display: flex; }
            .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > p {
              white-space: nowrap; }
        .sc-vehicle-select > div > section.section-container > .location-date-time-fields {
          width: 100%; }

.vehicle-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto; }
  .vehicle-header > div {
    display: flex; }

@media only screen and (min-width: 960px) {
  .select-model-subsection {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .login-container {
    display: flex;
    margin-left: auto;
    justify-content: center;
    margin-bottom: 1.2rem;
    align-items: flex-end; }
    .login-container > .login-to-pay-textfield {
      margin-top: inherit !important; } }

@media only screen and (max-width: 960px) {
  .vehicle-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .vehicle-header > div {
      display: flex;
      flex-direction: column; }
      .vehicle-header > div > div {
        display: flex; }
    .vehicle-header .included-services {
      display: flex;
      flex-direction: column; }
    .vehicle-header .select-model-subsection {
      display: flex;
      flex-direction: column; }
  .program-name {
    margin-top: 36px; }
  .sc-vehicle-select > div > section.section-container {
    padding: 0 20px; }
    .sc-vehicle-select > div > section.section-container > .section-header {
      display: flex;
      flex-direction: column;
      white-space: nowrap; }
      .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div {
        display: block; }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > div {
          display: flex; }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > .included-services {
          margin-top: 0.625rem;
          display: flex;
          flex-direction: column; }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > button > span .chevron-up {
          transform: rotate(270deg); }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > button > span .chevron-down {
          transform: rotate(90deg); }
      .sc-vehicle-select > div > section.section-container > .section-header .login-container {
        display: flex;
        justify-content: flex-start;
        margin-left: 0; }
      .sc-vehicle-select > div > section.section-container > .section-header.owner-program {
        padding-bottom: 15px;
        display: flex; } }

@media only screen and (max-width: 840px) {
  .sc-vehicle-select .select-model-subsection {
    flex-direction: column !important; } }

@media only screen and (max-width: 600px) {
  .sc-vehicle-select .section-header > div > div.sc-audi-owner-program {
    align-self: flex-start; } }

@media only screen and (max-width: 480px) {
  .sc-vehicle-select > div {
    -webkit-overflow-scrolling: touch; }
    .sc-vehicle-select > div > section.section-container {
      padding: 0 20px; }
      .sc-vehicle-select > div > section.section-container > .section-header {
        display: flex;
        flex-direction: column;
        white-space: nowrap; }
        .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div {
          display: block; }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > div {
            display: flex; }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > .included-services {
            margin-top: 0.625rem;
            display: flex;
            flex-direction: column; }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > button > span .chevron-up {
            transform: rotate(270deg); }
          .sc-vehicle-select > div > section.section-container > .section-header > div > .vehicle-header > div > button > span .chevron-down {
            transform: rotate(90deg); }
      .sc-vehicle-select > div > section.section-container > .location-date-time-fields.owner-program {
        display: block; } }
