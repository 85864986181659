.spent-loyalty-points-modal {
  padding: 0 70px 50px;
  text-align: center; }
  .spent-loyalty-points-modal > h2 {
    text-align: center;
    font-family: sba, sans-serif;
    font-size: 42px;
    font-weight: 500;
    color: #404040;
    text-transform: capitalize; }
  .spent-loyalty-points-modal > p {
    margin: 0 auto;
    font-family: sba, sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    color: #a2a2a2;
    max-width: 75%;
    margin-bottom: 24px; }
  .spent-loyalty-points-modal > .button {
    box-sizing: border-box;
    display: inline-block;
    font-family: sba, sans-serif;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    padding: 0 24px;
    height: 36px;
    line-height: 36px;
    min-width: 88px;
    color: white;
    border-radius: 18px;
    background-color: black;
    text-transform: capitalize;
    font-weight: 500; }

@media only screen and (max-width: 480px) {
  .spent-loyalty-points-modal {
    padding: 0 16px 34px; } }
