input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; }

.sc-form > section {
  display: flex;
  flex-flow: column; }
  .sc-form > section > header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 225px;
    text-transform: capitalize;
    color: white;
    background: black;
    background-position: center;
    background-size: cover; }
    .sc-form > section > header.no-bg {
      flex: 1 1 150px;
      background: none;
      color: #404040; }
    .sc-form > section > header.curbside {
      display: flex;
      flex-direction: column;
      background-color: white;
      color: black;
      max-height: 140px;
      justify-content: space-evenly;
      padding-top: 60px; }
    .sc-form > section > header > .sub-header {
      text-transform: none;
      color: #696767;
      font-size: 20px;
      padding: 15px;
      font-weight: 400; }
    .sc-form > section > header.login {
      background-image: url("../../assets/images/background/login.jpg"); }
    .sc-form > section > header.signup {
      background-image: url("../../assets/images/background/signup.jpg"); }
  .sc-form > section > .sub-header {
    font-size: 21px;
    line-height: 24px;
    color: #6a6767;
    text-align: center;
    width: 45%;
    margin: 50px auto 0;
    font-weight: 300; }
  .sc-form > section > section {
    display: flex;
    flex-flow: row; }
    .sc-form > section > section > * {
      margin-bottom: 16px; }
    .sc-form > section > section > form {
      display: flex;
      flex-flow: column;
      margin: auto;
      flex: 0 1 400px;
      margin-top: 40px; }
      .sc-form > section > section > form > div {
        text-align: left;
        width: 100% !important;
        margin: 12px 0; }
      .sc-form > section > section > form > .section-title {
        text-transform: capitalize;
        color: #adacac; }
        .sc-form > section > section > form > .section-title.padding {
          padding-top: 40px; }
      .sc-form > section > section > form > .button-link-group {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 120px;
        text-align: center; }
        .sc-form > section > section > form > .button-link-group > a {
          text-decoration: none;
          cursor: pointer;
          outline: none; }
  .sc-form > section > footer {
    display: flex;
    margin-bottom: 16px; }
    .sc-form > section > footer > div {
      margin: auto;
      flex: 0 1 400px;
      margin-top: 15px; }
      .sc-form > section > footer > div > * {
        display: block;
        padding: 8px 0; }
      .sc-form > section > footer > div p {
        margin: 8px 0; }

@media only screen and (max-width: 480px) {
  .sc-form > section > section {
    padding: 0 24px; }
  .sc-form > section > .sub-header {
    width: 85%; }
  .sc-form > section > footer {
    padding: 0 24px; } }
